import { useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { useEffect } from "react";

export const MasterHOC = (WrappedComponent) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>
        <WrappedComponent />
        <Footer />
    </>

}