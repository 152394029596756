import { useEffect, useState } from "react";
import { MENU_ITEMS_LIST } from "../assets/common/constants";
import { scrollWithOffset } from "../assets/common/functions";
import { NavHashLink } from "react-router-hash-link";

export const SubMenuBar = (props) => {

    const [activeSubMenu, setActionSubmenu] = useState('whyshift');

    useEffect(() => {
        setActionSubmenu(props.activeSubMenu.toLowerCase());
    }, [props.activeSubMenu]);

    return <div className={`menu-dropdown-item-holder ${props.status ? 'open' : ''}`}
        onMouseLeave={() => {
            props.onCloseSubMenu();
        }}
    >
        <div className='menu-dropdown-item-container'>

            <div className='menu-dropdown-item-content'>
                <h1 className='menu-dropdown-item-content-heading'>{MENU_ITEMS_LIST[activeSubMenu]?.heading}</h1>
                <p className='menu-dropdown-item-content-description'>{MENU_ITEMS_LIST[activeSubMenu]?.description}</p>
            </div>

            <div className='menu-dropdown-item-list-content'>

                {/* <h1 className='menu-dropdown-list-heading'>{MENU_ITEMS_LIST[activeSubMenu]?.subHeading}</h1> */}

                <ul className='menu-dropdown-item-list-content-subitem-holder'>
                    {
                        MENU_ITEMS_LIST[activeSubMenu]?.subList?.map((row, key) => {

                            return <li key={key} className='menu-dropdown-item-list-content-subitem-item'
                            >
                                <NavHashLink smooth to={row.target}
                                    onClick={() => { props.onCloseSubMenu(); }}
                                    scroll={el => { scrollWithOffset(el) }}
                                >
                                    {row.name}
                                </NavHashLink>
                            </li>
                        })
                    }
                </ul>

            </div>
        </div>
        <div className="submenu-overlay"></div>
    </div>
}