import React, { useState } from "react";
import { showNotification, validateEmail, validatePassword } from "../assets/common/functions";
import { API_BASE_URL, IMAGE_URL } from "../assets/common/constants";
import axios from "axios";
import { useEffect } from "react";
export const AdminLogin = () => {

    const [data, setData] = useState({ email: "", password: "" });

    useEffect(() => {
        document.documentElement.style.setProperty('--ternary-color', '#000');
        document.documentElement.style.setProperty('--secondary-color', '#fff');
    }, []);

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const onClickHandler = (e) => {
        e.preventDefault();

        if (!validateEmail(data.email)) {
            showNotification('Invalid Email');
            return;
        }
        if (!validatePassword(data.password)) {
            showNotification('Invalid password');
            return;
        }

        axios.post(API_BASE_URL + '/loginuser', data)
            .then((response) => {
                localStorage.setItem("token", response.data.accessToken);
                window.location = "/dashboard";
                showNotification('Admin Logged.');
            }).catch((err) => {
                showNotification(err.response.data.message);
            });

    };

    return (
        <div className='login_container'>
            <div className='login_form_container'>

                <div className="logo">
                    <img src={IMAGE_URL + 'logo.svg'} alt="" />
                </div>

                <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={data.email}
                    required
                    autocomplete="off"
                    className={'login_input'}
                />
                <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    value={data.password}
                    required
                    className={'login_input'}
                />
                <button className={'green_btn'} onClick={onClickHandler}>
                    Login
                </button>
            </div>
        </div>
    );
}