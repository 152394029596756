import { useEffect, useState } from "react";
import { API_BASE_URL, JSON_DATA } from "../assets/common/constants";
import { API_CALL } from "../assets/common/functions";
import { Solutions } from "../components/Solutions";

export const DevOps = () => {
    // const [data, setData] = useState(JSON_DATA.devops);
    const [data, setData] = useState();

    useEffect(() => {
        document.documentElement.style.setProperty('--secondary-color', '#000');
        document.documentElement.style.setProperty('--ternary-color', '#fff');
        document.documentElement.style.setProperty('--footer-text-color', '#CDCDCD');

        API_CALL(API_BASE_URL + '/devopsdata', (res) => {
            setData(res.devops);
        }, (err) => { });
    }, []);

    return <>
        <div className={`loader-holder ${data ? 'hide' : ''}`}>
            <span className="loader"></span>
        </div>
        {
            data
                ? <Solutions
                    bannerImage={data.bannerImage}
                    bannerContent={data.bannerContent}
                    pieceDesignList={data.pieceDesignList}
                    paragraphList={data.paragraphList}
                    outcomesContent={data.outcomesContent}
                />
                : ''
        }
    </>
}