import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MasterHOC } from "../HOC/MasterHOC";
import { Carousel } from "../components/Carousel";
import { API_BASE_URL, JSON_DATA } from "../assets/common/constants";
import { convertHtmlToReact } from "@hedgedoc/html-to-react";
import { API_CALL } from "../assets/common/functions";

export const Home = () => {

    const HomePage = () => {

        let navigate = useNavigate();
        const [data, setData] = useState(JSON_DATA.home);

        useEffect(() => {
            document.documentElement.style.setProperty('--secondary-color', '#fff');
            document.documentElement.style.setProperty('--ternary-color', '#000');
            document.documentElement.style.setProperty('--footer-text-color', '#878787');

            API_CALL(API_BASE_URL + '/homedata', (res) => {
                setData(res.home);
            }, (err) => { });
        }, []);

        return <>

            <Carousel bannerData={data?.banner} />

            <section className={`home-section welcome-section`} data-aos="fade-up">
                <div className="left-block">

                    <div className="content-block full-width">
                        <p className="b-text-content">{data.aboutsection?.heading}</p>
                        <p className="s-text-content">{data.aboutsection?.paragraphContent}</p>
                    </div>
                    <button className="btn btn1"
                        onClick={() => {
                            navigate(data.aboutsection?.action_target);
                        }}>{data.aboutsection?.action_name}</button>

                </div>
                <div className="right-block">

                    <div className="salesforce-partner">
                        <img src={'https://yugashift.com/images/salesforce_partner.png'} alt="" />
                    </div>
                </div>

            </section>

            <section className="puzzle-flip-section">

                <ul className="puzzle-flip-container">

                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[0].action_target);
                        }}>
                            <p className="s-text-content-1"><img src={data.puzzleflipsection?.[0].image} alt="" /></p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[0].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="bottom-right-dot"></span>
                            <span className="center-bottom-right-dot"></span>
                        </div>
                    </li>

                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[1].action_target);
                        }}>
                            <p className="s-text-content-1">{convertHtmlToReact(data.puzzleflipsection?.[1].heading.join(''))}</p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[1].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="top-right-dot"></span>
                            <span className="bottom-left-dot"></span>
                            <span className="bottom-right-dot"></span>

                            <span className="center-top-left-dot"></span>
                            <span className="center-top-right-dot"></span>
                            <span className="center-bottom-left-dot"></span>
                            <span className="center-bottom-right-dot"></span>

                        </div>
                    </li>

                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[2].action_target);
                        }}>
                            <p className="s-text-content-1">{convertHtmlToReact(data.puzzleflipsection?.[2].heading.join(''))}</p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[2].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="top-right-dot"></span>
                            <span className="bottom-left-dot"></span>
                            <span className="bottom-right-dot"></span>
                            <span className="center-bottom-left-dot"></span>
                            <span className="center-bottom-right-dot"></span>
                            <span className="center-top-left-dot"></span>
                        </div>
                    </li>

                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[3].action_target);
                        }}>
                            <p className="s-text-content-1">{convertHtmlToReact(data.puzzleflipsection?.[3].heading.join(''))}</p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[3].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="bottom-left-dot"></span>
                            <span className="bottom-right-dot"></span>
                            <span className="center-top-left-dot"></span>
                            <span className="center-bottom-left-dot"></span>
                            <span className="center-bottom-right-dot"></span>
                        </div>
                    </li>

                    <div className="background-overlay"></div>

                </ul>
                <ul className="puzzle-flip-container">
                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[4].action_target);
                        }}>
                            <p className="s-text-content-1">{convertHtmlToReact(data.puzzleflipsection?.[4].heading.join(''))}</p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[4].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="top-right-dot"></span>
                            <span className="bottom-right-dot"></span>
                            <span className="center-top-right-dot"></span>
                            <span className="center-top-left-dot"></span>
                            <span className="center-bottom-right-dot"></span>

                        </div>
                    </li>
                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[5].action_target);
                        }}>
                            <p className="s-text-content-1">{convertHtmlToReact(data.puzzleflipsection?.[5].heading.join(''))}</p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[5].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="top-right-dot"></span>
                            <span className="bottom-left-dot"></span>
                            <span className="bottom-right-dot"></span>

                            <span className="center-top-left-dot"></span>
                            <span className="center-top-right-dot"></span>
                            <span className="center-bottom-right-dot"></span>

                        </div>
                    </li>
                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[6].action_target);
                        }}>
                            <p className="s-text-content-1">{convertHtmlToReact(data.puzzleflipsection?.[6].heading.join(''))}</p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[6].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="top-right-dot"></span>
                            <span className="bottom-left-dot"></span>
                            <span className="bottom-right-dot"></span>

                            <span className="center-top-right-dot"></span>
                            <span className="center-top-left-dot"></span>
                            <span className="center-bottom-left-dot"></span>
                            <span className="center-bottom-right-dot"></span>
                        </div>
                    </li>
                    <li className="puzzle-hover-card-holder">
                        <div className="hover-card" onClick={() => {
                            navigate(data.puzzleflipsection?.[7].action_target);
                        }}>
                            <p className="s-text-content-1">{convertHtmlToReact(data.puzzleflipsection?.[7].heading.join(''))}</p>
                            <p className="s-text-content-2">{data.puzzleflipsection?.[7].content}</p>
                            <span className="border-line"></span>

                            <span className="top-left-dot"></span>
                            <span className="bottom-right-dot"></span>
                            <span className="center-top-left-dot"></span>
                        </div>
                    </li>

                    <div className="background-overlay"></div>
                </ul>

            </section>

            <section className={`we-cover-section`} >

                <div className="content-container" data-aos="fade-right">
                    <div className="content-block">
                        <p className="b-text-content">{data.wecoversection?.heading}</p>
                        <p className="s-text-content">{data.wecoversection?.paragraphContent}</p>
                    </div>
                    <button className="btn btn1" onClick={() => {
                        navigate(data.wecoversection.action_target);
                    }}>{data.wecoversection?.action_name}</button>
                </div>

                <div className="technologies-block" data-aos="fade-left">
                    {
                        data.wecoversection.technologiesimages.map((row, key) => {
                            return <div className="technologies-block-item">
                                <img src={row.image} alt="" />
                            </div>
                        })
                    }
                </div>

            </section>

            <section className={`home-section why-yuga-shift-section`} >


                <div className="content-block" data-aos="fade-up">
                    <p className="b-text-content">{data.whyshiftsection?.heading}</p>
                    <p className="s-text-content">{data.whyshiftsection?.paragraphContent}</p>
                </div>

                <div className="why-yuga-shift-block">
                    {
                        data.whyshiftsection.items.map((row, key) => {
                            return <div className="why-yuga-shift-block-item" data-aos="fade-up">
                                <img src={row.image} alt="" />
                                <p className="b-text-content">{row.heading}</p>
                                <p className="s-text-content">{row.paragraphContent}</p>
                            </div>
                        })
                    }
                </div>

                <button className="btn btn1" data-aos="fade-up" onClick={() => {
                    navigate(data.whyshiftsection.action_target);
                }}>{data.whyshiftsection.action_name}</button>
            </section>
        </>
    }

    return MasterHOC(HomePage);
}