import axios from "axios";
import { useRef } from "react";
import { API_BASE_URL } from "../assets/common/constants";
import { showNotification, validatePassword } from "../assets/common/functions";

export const AdminUserDetails = (props) => {

    const passwordref = useRef(undefined);

    const closePopup = (e) => {
        props.toogleUserDetailsPopup(false);
    }

    const updatePassword = async () => {

        if (!validatePassword(passwordref.current.value)) {
            showNotification("invalid password");
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }

        let userObj = {
            email: props.userDetails.email,
            password: passwordref.current.value
        }
        await axios.post(API_BASE_URL + '/passwordupdate', userObj, {
            headers: headers
        }).then((response) => {
            showNotification('Password updated succesfully');
        });
    }

    return <div className="admin-user-details" onClick={(e) => {
        closePopup();
    }}>
        <section className="admin-user-details-container" onClick={e => e.stopPropagation()}>

            <h1>User Details</h1>

            <div className="admin-user-details-container-input-holder">

                <div className="admin-user-input">
                    <label htmlFor="new-password-input">Username</label>
                    <input disabled type="text" value={props.userDetails.name} />
                </div>

                <div className="admin-user-input">
                    <label htmlFor="new-password-input">Email</label>
                    <input disabled type="text" value={props.userDetails.email} />
                </div>

                <br />
                <div className="admin-user-input">
                    <label htmlFor="new-password-input">New Password</label>
                    <div className="update-button-holder">
                        <input ref={passwordref} id="new-password-input" autoFocus type="text"
                            onChange={() => {

                            }} />
                        <button className="update-btn" onClick={() => { updatePassword() }}>Update</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
}