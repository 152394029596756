import { useEffect } from "react";
import { MasterHOC } from "../HOC/MasterHOC";

export const PrivacyPolicy = () => {

    const PrivacyPolicyPage = () => {
        useEffect(() => {
            document.documentElement.style.setProperty('--secondary-color', '#fff');
            document.documentElement.style.setProperty('--ternary-color', '#000');
            document.documentElement.style.setProperty('--footer-text-color', '#878787');
        }, []);
        return <section className={`home-section`}>
            <div className="privacy-content-block">
                <br />
                <p className="b-text-content heading">Privacy and Cookies Policy</p>
                <p className="s-text-content">Yuga Shift Corporation, together with its subsidiaries ("Yuga Shift") has created this Privacy
                    Policy in line with YUGA SHIFT's commitment to your privacy on YUGA SHIFT websites and
                    marketing initiatives. The following discloses YUGA SHIFT's information gathering,
                    dissemination and protection practices.</p>

                <br />
                <p className="b-text-content">Compliance</p>
                <p className="s-text-content">If you do not agree to the terms of this Privacy Policy, please do not access or use YUGA SHIFT websites. If you wish to stop receiving YUGA SHIFT marketing materials then please click on the Unsubscribe button within the marketing email you have received from us.</p>

                <br />
                <p className="b-text-content">Collection of information</p>
                <p className="s-text-content">YUGA SHIFT collects information to provide better services to its users and to better understand the visitors to its websites and what content is of interest to them. YUGA SHIFT collects information in the following ways:</p>

                <ul>
                    <li className="s-text-content">Information you affirmatively choose to submit to us (such as your name, email address, company name, title, country) for the purpose of accessing various downloads, viewing specific content, subscribing to YUGA SHIFT newsletters, registering for events and your preferences in receiving marketing from YUGA SHIFT and its third party partners and your communication preferences, etc. Information we have obtained about you from publicly available sources such as social media, marketing platforms or on events, you attended. You will receive marketing communications from YUGA SHIFT if you have requested information from YUGA SHIFT or you or your employer has purchased services from YUGA SHIFT;</li>
                    <li className="s-text-content">Information YUGA SHIFT receives from your use of YUGA SHIFT websites, such as the IP address, browser type, ISP, referring/exit pages, platform type, date/time stamp, number of clicks, domain name and country/state of all visitors to YUGA SHIFT's websites;</li>
                    <li className="s-text-content">Location information when you use YUGA SHIFT's websites as YUGA SHIFT may collect and process information about your location. YUGA SHIFT use various technologies and tools to determine location;</li>
                    <li className="s-text-content">Information that is considered aggregated data such as statistical or demographic data which YUGA SHIFT may use for any purpose. Aggregated data may be derived from your personal information but is not considered personal information in law as it does not directly or indirectly reveal your identity, unless YUGA SHIFT combines or connects aggregated data with your personal information so that it can directly or indirectly identify you; and</li>
                    <li className="s-text-content">YUGA SHIFT collects technical data from various third parties and public sources as, such as:
                        <ul>
                            <li className="s-text-content text-indent">Analytics Providers</li>
                            <li className="s-text-content text-indent">Advertising Networks</li>
                            <li className="s-text-content text-indent">Search Information Providers</li>
                        </ul>
                    </li>
                </ul>
                <p className="s-text-content">Other than as required by law, YUGA SHIFT websites do not collect any special categories of personal information about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). YUGA SHIFT will not collect any information about criminal convictions and offenses.</p>
                <p className="s-text-content">YUGA SHIFT will only use your personal information for the purposes for which it was collected, unless it reasonably considers that it needs to use it for another reason that is compatible with the original purpose. If YUGA SHIFT needs to use your personal information for an unrelated purpose, YUGA SHIFT will notify you and explain the legal basis which allows YUGA SHIFT to do so.</p>

                <br />
                <p className="b-text-content">Sharing of Information</p>
                <p className="s-text-content">YUGA SHIFT may disclose your personal information to the following categories of recipients:</p>

                <ul>
                    <li className="s-text-content">to its <b>group companies, professional advisers, third party services providers and partners</b> who provide data processing services to YUGA SHIFT (for example, to support the delivery of, provide functionality on, or help to enhance the security of YUGA SHIFT websites), or who otherwise process personal information for purposes that are described in Privacy Notices or notified to you when YUGA SHIFT collects your personal information. A list of YUGA SHIFT's current group companies is available upon request.</li>
                    <li className="s-text-content">to any <b>competent law enforcement body, regulatory, government agency, court or other third party</b> where YUGA SHIFT believes disclosure is necessary (a) as a matter of applicable law or regulation, (b) to exercise, establish or defend its legal rights, or (c) to protect your vital interests or those of any other person.</li>
                    <li className="s-text-content">to any <b>other person with your consent</b> to the disclosure.</li>
                </ul>

                <br />
                <p className="b-text-content">Legal Basis of Processing</p>
                <p className="s-text-content">If your personal information is protected by EU data protection law, YUGA SHIFT's legal basis for collecting and using the personal information described above will depend on the personal information concerned and the specific context in which YUGA SHIFT collects it.</p>
                <p className="s-text-content">However, YUGA SHIFT will normally collect personal information from you only (a) where it needs the personal information to perform a contract with you, (b) where the processing is in its legitimate interests and is not overridden by your rights, or (c) where it has your consent to do so. In some cases, YUGA SHIFT may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person.</p>
                <p className="s-text-content">If you have questions about or need further information concerning the legal basis on which YUGA SHIFT collects and uses your personal information, please contact YUGA SHIFT using the contact details provided under the "Contact Details" heading below and in the righthand side column.</p>


                <br />
                <p className="b-text-content">Use of Information</p>
                <p className="s-text-content">Any information collected by YUGA SHIFT will be done in accordance with this Privacy Policy for the purposes of making better business decisions, supporting the activities you choose to engage in, and providing higher quality solutions and services to YUGA SHIFT's customers. </p>
                <p className="s-text-content">YUGA SHIFT uses your location information to tailor your experience with YUGA SHIFT websites. It may also use any non-personal information collected to enhance YUGA SHIFT websites, and your IP address for website diagnostics and administration. YUGA SHIFT will only retain your personal information for as long as necessary to fulfill the purposes it was collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                <p className="s-text-content">To determine the appropriate retention period for personal information, YUGA SHIFT considers the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which YUGA SHIFT processes your personal information and whether it can achieve those purposes through other means, and the applicable legal requirements.</p>

                <br />
                <p className="b-text-content">International Transfers</p>
                <p className="s-text-content">YUGA SHIFT processes information on servers in many countries around the world and you consent to your personal information being processed on a server located outside the country in which you reside. These countries may have data protection laws that are different to the laws of your country (and, in some cases, may not be as protective). However, YUGA SHIFT has taken appropriate safeguards to require that your personal information will remain protected in accordance with this Privacy Policy, including, utilizing the European Commission's Standard Contractual Clauses for transfers of personal information between its group companies, which requires all group companies to protect personal information being processed from the European Economic Area to an equivalent standard to that required under European Union data protection law.</p>

                <br />
                <p className="b-text-content">Information Security</p>
                <p className="s-text-content">YUGA SHIFT protects your personal information in the same way that it protects its other confidential information, such as storing it on secure servers that only select YUGA SHIFT personnel have access to, and by encrypting it to help prevent access by any unauthorized parties. YUGA SHIFT has put in place procedures to deal with any personal information breach and will notify you and any applicable regulator of a breach when legally required to do so.</p>

                <br />
                <p className="b-text-content">Marketing</p>
                <p className="s-text-content">As explained above, YUGA SHIFT will collect and use your personal data for marketing purposes. Your data will be stored in our CRM system. We will use your data to inform you about YUGA SHIFT products and services we think may be of your interest and sending you our newsletters. For statistical purposes, we will collect information about your response to our emails and our interaction with you to make decisions on future marketing. If you wish to stop that we collect and use this information please contact <a href="mailto:info@yugashift.com">info@yugashift.com</a></p>

                <br />
                <p className="b-text-content">Links</p>
                <p className="s-text-content">YUGA SHIFT websites contains links, plug-ins and applications to various third party websites. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. YUGA SHIFT has no control over, and is not responsible for the content, privacy policies or reliability of such third party connections. When you leave YUGA SHIFT's website, YUGA SHIFT encourages you to read the privacy notice and privacy policy of every website you visit.</p>

                <br />
                <p className="b-text-content">Your Data Protection Rights</p>
                <p className="s-text-content">If your personal information is protected by EU data protection law, you have the following data protection rights which you may be able to exercise by contacting YUGA SHIFT using the contact details provided under the "Contact Details" heading below and in the righthand side column:</p>
                <ul>
                    <li className="s-text-content">If you wish to request deletion of your personal information, you can do that by email to <a href="mailto:info@yugashift.com">info@yugashift.com</a></li>
                    <li className="s-text-content">If you wish to access, correct, update your personal information, you can do so at any time by contacting YUGA SHIFT.</li>
                    <li className="s-text-content">In addition, you can object to processing of your personal information, ask YUGA SHIFT to restrict processing of your personal information or request portability of your personal information by contacting YUGA SHIFT.</li>
                    <li className="s-text-content">You have the right to opt-out of marketing communications that YUGA SHIFT sends you at any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails YUGA SHIFT sends you or by using the relevant opt-out mechanism for non-email based marketing.</li>
                    <li className="s-text-content">Similarly, if YUGA SHIFT has collected and processes your personal information based on your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing YUGA SHIFT conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</li>
                    <li className="s-text-content">You have the right to complain to a data protection authority about YUGA SHIFT's collection and use of your personal information. For more information, please contact your local data protection authority.
                    </li>
                </ul>

                <br />
                <p className="s-text-content">You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, YUGA SHIFT may charge a reasonable fee or refuse to comply with your request if your request is clearly unfounded, repetitive or excessive. YUGA SHIFT tries to respond to all legitimate requests within one month. Occasionally, it may take longer if your request is particularly complex or you have made a number of requests. In this case, YUGA SHIFT will notify you and keep you updated.</p>

                <br />
                <p className="b-text-content">Cookies</p>
                <p className="s-text-content">You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies some parts of YUGA SHIFT websites may become inaccessible or not function properly. Changes to the Policy</p>
                <p className="s-text-content">YUGA SHIFT reserves the right to modify or amend this Privacy Policy at its own discretion from time to time. The changes can be viewed here or elsewhere on YUGA SHIFT websites. Your continued use of YUGA SHIFT websites constitutes your acceptance of those changes. This version was last updated on September 2nd, 2023, and historic versions can be obtained by contacting YUGA SHIFT.</p>

                <br />
                <p className="b-text-content">Contact Details</p>
                <p className="s-text-content">It is important that the personal information YUGA SHIFT holds about you is accurate and current. Please keep YUGA SHIFT informed if your personal information changes during your relationship with YUGA SHIFT. Additionally, if you have any questions about this Privacy Policy, the practices of YUGA SHIFT websites or your interaction with YUGA SHIFT via YUGA SHIFT websites, please contact:</p>

                <br />
                <b className="b-text-content">YUGA SHIFT Corporation</b>
                <p className="s-text-content">E: <a href="mailto:info@yugashift.com">info@yugashift.com</a></p>
            </div>
        </section>
    }
    return MasterHOC(PrivacyPolicyPage);
}