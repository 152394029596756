
import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import { API_BASE_URL, IMAGE_URL, JSON_DATA } from '../assets/common/constants';
import { useState, useEffect } from 'react';
import { API_CALL } from '../assets/common/functions';

export const ContactPopup = (props) => {

    const [data, setData] = useState(JSON_DATA.contact);

    useEffect(() => {
        API_CALL(API_BASE_URL + '/contactdata', (res) => {
            setData(res.contact);
        }, (err) => { });
    }, []);

    return (<div className={`contact-popup-container ${props.status ? 'active' : ''}`}>

        <div className='header'>
            <div className="logo">
                <img src={IMAGE_URL + 'logo.svg'} alt="" />
            </div>

            <svg onClick={() => {
                props.onContactPopupClick(false);
            }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>

        <div className='contact-description'>{data.description}</div>

        <div className='contact-information'>
            <div className='address-info'>
                <svg viewBox="0 40 138 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M69.0885 169.9C100.983 137.095 132.877 107.719 132.877 71.4836C132.877 35.2476 104.318 5.87256 69.0885 5.87256C33.8591 5.87256 5.30005 35.2476 5.30005 71.4836C5.30005 107.719 37.1943 137.095 69.0885 169.9Z" stroke="currentColor" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M69.0887 96.9991C84.1874 96.9991 96.4266 84.7599 96.4266 69.6612C96.4266 54.5629 84.1874 42.3232 69.0887 42.3232C53.9899 42.3232 41.7507 54.5629 41.7507 69.6612C41.7507 84.7599 53.9899 96.9991 69.0887 96.9991Z" stroke="currentColor" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {convertHtmlToReact(data.address.join(''))}
            </div>

            <div className='address-info'>
                <svg viewBox="0 0 142 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 19.7499L57.95 53.462C65.6836 59.262 76.3164 59.262 84.05 53.462L129 19.7495" stroke="currentColor" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M121.75 5.25H20.25C12.2419 5.25 5.75 11.7419 5.75 19.75V92.25C5.75 100.258 12.2419 106.75 20.25 106.75H121.75C129.758 106.75 136.25 100.258 136.25 92.25V19.75C136.25 11.7419 129.758 5.25 121.75 5.25Z" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                </svg>

                <div>
                    {
                        data.email.map((row, key) => {
                            return <a key={key} href={`mailto:${row}`}>{row}<br /></a>
                        })
                    }
                </div>
            </div>

            <div className='address-info'>
                <svg viewBox="0 0 134 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 22.5833C5.5 81.0794 52.9206 128.5 111.417 128.5C114.056 128.5 116.672 128.404 119.263 128.214C122.236 127.996 123.723 127.887 125.076 127.108C126.197 126.463 127.26 125.319 127.822 124.154C128.5 122.748 128.5 121.107 128.5 117.826V98.5748C128.5 95.8155 128.5 94.4358 128.046 93.2537C127.645 92.2089 126.993 91.2788 126.149 90.5449C125.192 89.714 123.896 89.2425 121.303 88.2995L99.3897 80.3312C96.3729 79.2344 94.8645 78.6857 93.4334 78.7786C92.1715 78.8606 90.9571 79.2918 89.9254 80.023C88.7554 80.8519 87.9296 82.2281 86.2781 84.9812L80.6667 94.3333C62.559 86.1327 47.8796 71.4342 39.6667 53.3333L49.0188 47.7219C51.7719 46.0704 53.1481 45.2446 53.977 44.0746C54.7082 43.0429 55.1394 41.8285 55.2214 40.5666C55.3143 39.1355 54.7656 37.6271 53.6688 34.6103L45.7005 12.6969C44.7575 10.104 44.286 8.80754 43.4551 7.85135C42.7212 7.00668 41.7911 6.35519 40.7463 5.95408C39.5642 5.5 38.1845 5.5 35.4252 5.5H16.1737C12.893 5.5 11.2523 5.5 9.846 6.17821C8.68091 6.73998 7.53701 7.8029 6.89194 8.9237C6.11294 10.277 6.0043 11.7636 5.78632 14.7368C5.59635 17.3275 5.5 19.9442 5.5 22.5833Z" stroke="currentColor" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div>

                    {
                        data.phno.map((row, key) => {
                            return <a key={key} href={`tel:${row.number}`}>{row.displayNum}<br /></a>
                        })
                    }

                </div>
            </div>

        </div>

    </div>)
}