import { useEffect, useState } from "react";
import { API_BASE_URL, JSON_DATA, MENU_ITEMS_LIST } from "../assets/common/constants";
import { MasterHOC } from "../HOC/MasterHOC";
import { convertHtmlToReact } from "@hedgedoc/html-to-react";
import { API_CALL } from "../assets/common/functions";

export const Technology = () => {

    const TechnologyPage = () => {

        const [data, setData] = useState(JSON_DATA.technology);

        useEffect(() => {
            document.documentElement.style.setProperty('--secondary-color', '#000');
            document.documentElement.style.setProperty('--ternary-color', '#fff');
            document.documentElement.style.setProperty('--footer-text-color', '#CDCDCD');

            API_CALL(API_BASE_URL + '/technologydata', (res) => {
                setData(res.technology);
            }, (err) => { });
        }, []);

        return <>
            {/* =================================================================== */}

            <section className={`technology-section`}>
                <div className="content-block">
                    <p className="b-text-content" data-aos="fade-right">{convertHtmlToReact(data.bannerContent.join(''))}</p>
                    <p className="s-text-content" data-aos="fade-right">{data.bannerParagraphContent}</p>
                </div>

            </section>

            {/* =================================================================== */}

            <div className={`technology-list`}>
                {
                    data.technologyList.map((row, key) => {
                        return <div key={key} id={MENU_ITEMS_LIST['technologies'].subList[parseInt(row.id - 1)].name.replaceAll(' ', '').toLowerCase()} className="card-holder" data-aos={(key % 2 === 0) ? "fade-right" : "fade-left"}
                            data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                            <div className="card">

                                <div className="front">
                                    <picture>
                                        {
                                            row.mobileimgSrc
                                                ? <source media="(max-width:768px)" srcSet={row.mobileimgSrc} />
                                                : ''
                                        }
                                        <img src={row.imgSrc} alt="" />
                                    </picture>
                                    {
                                        row.name ? <p>{row.name}</p> : ''
                                    }
                                </div>
                                <div className="back">{row.content}</div>
                                <span className="border-line"></span>
                            </div>
                        </div>
                    })
                }
            </div>
        </>
    }
    return MasterHOC(TechnologyPage);
}