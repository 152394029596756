import { useEffect, useState } from "react";
import { MasterHOC } from "../HOC/MasterHOC";
import { API_BASE_URL, JSON_DATA } from "../assets/common/constants";
import { API_CALL } from "../assets/common/functions";
import { convertHtmlToReact } from "@hedgedoc/html-to-react";

export const About = () => {

    const AboutPage = () => {

        const [data, setData] = useState(JSON_DATA.about);

        useEffect(() => {
            document.documentElement.style.setProperty('--secondary-color', '#000');
            document.documentElement.style.setProperty('--ternary-color', '#fff');
            document.documentElement.style.setProperty('--footer-text-color', '#CDCDCD');

            API_CALL(API_BASE_URL + '/aboutdata', (res) => {
                setData(res.about);
            }, (err) => { });
        }, []);

        return <>

            {/* =================================================================== */}

            <section id="vision" className={`about-section`} data-aos="fade-right">
                <div className="content-block">
                    <p className="b-text-content" data-aos="fade-right">{convertHtmlToReact(data?.bannerContent.join(''))}</p>
                    <p className="s-text-content" data-aos="fade-right" data-aos-delay="100"
                    >{convertHtmlToReact(data?.bannerParagraphContent.join(''))}</p>
                </div>
            </section>

            {/* ========= Our Mission ========================================================== */}

            <section id="mission" className="our-mission-section" >

                <div className="content-block">
                    <p className="b-text-content" data-aos="fade-right" data-aos-delay="100">{data?.ourmission?.heading}</p>
                    <p className="s-text-content" data-aos="fade-right" data-aos-delay="200">{data?.ourmission.content}</p>
                </div>

                <ul className="mission-platform-list">
                    {
                        data?.ourmission.platformlist.map((row, key) => {
                            return <li key={key} data-aos="fade-up"> <img src={row.imgSrc} alt="" />{row.name}</li>
                        })
                    }
                </ul>

                <div className="content-block right">
                    <p className="s-text-content" data-aos="fade-left" data-aos-delay="100">{data?.ourmission.paragraphContent_1}</p>
                    <p className="s-text-content" data-aos="fade-left" data-aos-delay="300">{data?.ourmission.paragraphContent_2}</p>
                </div>

                <div className="piece-design-block" data-aos="fade-right" data-aos-delay="500">
                    <div className="piece-design"></div>
                    <p className="s-text-content">{data?.ourmission.leftPieceContent}</p>
                </div>

            </section >

            {/* ========= Our Team ========================================================== */}

            <section id="team" className="our-team-section" >

                <div className="content-block">
                    <p className="b-text-content" data-aos="fade-up" data-aos-delay="200">{data?.ourteam.heading}</p>
                    <p className="s-text-content" data-aos="fade-up" data-aos-delay="300">{data?.ourteam.content}</p>
                </div>

                <div className="piece-design-block right" data-aos="fade-left" data-aos-delay="500" style={{ marginTop: 'unset' }}>
                    <div className="piece-design"></div>
                    <p className="s-text-content">{data?.ourteam.rightPieceContent}</p>
                </div>

            </section >
        </>
    }

    return MasterHOC(AboutPage)
}