import { useEffect, useState } from "react";
import { MENU_ITEMS_LIST } from "../assets/common/constants";
import { NavHashLink } from "react-router-hash-link";
import { scrollWithOffset } from "../assets/common/functions";

export const MobileSubMenuBar = (props) => {

    const [activeSubMenu, setActiveSubmenu] = useState('about');
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const onItemClickHandler = (row) => {
        if (row.name === "contact") {
            props.onCloseMobileSubMenu();
            props.onContactPopupClick(true);
            return;
        }
        setActiveSubmenu(row.name);
        setSubMenuOpen(true);
    }

    const onBackClickHandler = () => {
        setSubMenuOpen(false);
    }

    useEffect(() => {
        if (!props.status) {
            onBackClickHandler();
        }
    }, [props.status]);

    return <>
        <ul className={`mobile-view-submenu-container ${props.status ? 'active' : ''} ${isSubMenuOpen ? 'submenu' : ''}`}>

            {
                Object.entries(MENU_ITEMS_LIST).map(([key, value], index) => {
                    return <li key={index} className='mobile-view-submenu-item' onClick={() => onItemClickHandler(value)}>
                        {value.heading}
                        <span className='submenu-item-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </span>
                    </li>
                })
            }
        </ul>

        <div className={`mobile-view-submenu-container-description ${isSubMenuOpen && props.status ? 'active' : ''}`}>
            <div className='mobile-view-submenu-description-item-heading'> {MENU_ITEMS_LIST[activeSubMenu].heading}</div>
            <div className='mobile-view-submenu-description-item-description'>{MENU_ITEMS_LIST[activeSubMenu].description}</div>


            <div className="mobile-view-submenu-description-item-list-content">
                {/* <h1 className="mobile-view-submenu-description-item-list-heading">{MENU_ITEMS_LIST[activeSubMenu].subHeading}</h1> */}
                <ul className="mobile-view-submenu-description-item-list-content-subitem-holder">
                    {
                        MENU_ITEMS_LIST[activeSubMenu].subList.map((row, key) => {
                            return <li key={key} className="mobile-view-submenu-description-item-content-subitem-item">
                                <NavHashLink smooth to={row.target} onClick={() => { props.onCloseMobileSubMenu(); }} scroll={el => { scrollWithOffset(el) }}>{row.name}</NavHashLink>
                            </li>
                        })
                    }
                </ul>
            </div>
            <div className="mobile-view-back-arrow" onClick={onBackClickHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
            </div>
        </div>

    </>
}