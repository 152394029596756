import { MasterHOC } from "../HOC/MasterHOC";
import { useEffect, useRef, useState } from "react";
import { API_BASE_URL, JSON_DATA } from "../assets/common/constants";
import { API_CALL } from "../assets/common/functions";
import { convertHtmlToReact } from "@hedgedoc/html-to-react";

export const WhyShift = () => {

    const WhyShiftPage = () => {

        let varstore = useRef(useState);
        const [data, setData] = useState(JSON_DATA.whyshift);

        useEffect(() => {
            document.documentElement.style.setProperty('--secondary-color', '#000');
            document.documentElement.style.setProperty('--ternary-color', '#fff');
            document.documentElement.style.setProperty('--footer-text-color', '#CDCDCD');

            API_CALL(API_BASE_URL + '/whyshiftdata', (res) => {
                setData(res.whyshift);
            }, (err) => { });

        }, []);

        useEffect(() => {

            let offsetValue = 30;
            let paddingValue = ((varstore?.fullpiecedesign?.clientHeight * 20 / 100) / 2) + (offsetValue / 2);
            varstore.fullpiecedesigncontent.style.padding = `${paddingValue}px ${offsetValue}px`;
        }, [varstore.fullpiecedesign]);

        return <>

            {/* =================================================================== */}

            <section className={`why-shift-section`} data-aos="fade-right">
                <div className="content-block">
                    <p className="b-text-content">{convertHtmlToReact(data.bannerContent.join(''))}</p>
                    <p className="s-text-content">{data.bannerParagraphContent}</p>
                </div>
            </section>


            <section id="speed_to_value" className="why-shift-content-section" >
                <div className="content-block half-width" data-aos="fade-up">
                    <p className="b-text-content">{data.speedtovalue.name}</p>
                    <p className="s-text-content">{data.speedtovalue.description}</p>
                </div>

                <div className="image-block" data-aos="fade-left">
                    <img src={data.speedtovalue.imgSrc} alt="speed to value" />
                </div>
            </section>

            <section id="uncompromising_quality" className="why-shift-content-section inverse" >

                <div className="content-block half-width" data-aos="fade-up">
                    <p className="b-text-content">{data.uncompromisingquality.name}</p>
                    <p className="s-text-content">{data.uncompromisingquality.description}</p>
                </div>
                <div className="image-block end" data-aos="fade-right">
                    <img src={data.uncompromisingquality.imgSrc} alt="Uncompromising Quality" />
                </div>
            </section>

            <section id="outcome_oriented" className="why-shift-content-section">
                <div className="content-block half-width" data-aos="fade-up">
                    <p className="b-text-content">{data.outcomeorientedengagement.name}</p>
                    <p className="s-text-content">{data.outcomeorientedengagement.description}</p>
                </div>

                <div className="image-block" data-aos="fade-left">
                    <img src={data.outcomeorientedengagement.imgSrc} alt="Outcome Oriented Engagement" />
                </div>
            </section>

            <section id="competitive_pricing" className="why-shift-content-section inverse" >
                <div className="content-block half-width" data-aos="fade-up">
                    <p className="b-text-content">{data.competitivepricing.name}</p>
                    <p className="s-text-content">{data.competitivepricing.description}</p>
                </div>
                <div className="image-block end" data-aos="fade-right">
                    <img src={data.competitivepricing.imgSrc} alt="Competitive Pricing" />
                </div>
            </section>

            <section className="why-shift-content-section" data-aos="fade-up">
                <div className="full-piece-design-block full-width" ref={elem => varstore.fullpiecedesign = elem}>
                    <div className="left-piece-design"></div>
                    <p className="s-text-content" ref={elem => varstore.fullpiecedesigncontent = elem}>
                        {data.choosingyugashift}
                    </p>
                    <div className="right-piece-design"></div>
                </div>
            </section>
        </>

    }
    return MasterHOC(WhyShiftPage);
}