import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { About } from "./pages/About";
import { Advisory } from "./pages/Advisory";
import { CRMAnalytics } from "./pages/CRMAnalytics";
import { DevOps } from "./pages/DevOps";
import { Implementation } from "./pages/Implementation";
import { Integration } from "./pages/Integration";
import { ManageServices } from "./pages/ManageServices";
import { CustomDevelopment } from "./pages/CustomDevelopment";
import { Technology } from "./pages/Technology";
import { WhyShift } from "./pages/WhyShift";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { ErrorPage } from "./pages/404Page";
import { MenuBar } from "./components/MenuBar";
import { ContactPopup } from "./components/ContactPopup";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { AdminLogin } from "./pages/AdminLogin";
import Aos from "aos";
import { useLocation } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { ToastContainer } from "react-toastify";

const App = () => {

  let location = useLocation();
  const [openContactPopup, setOpenContactPopup] = useState(false);

  const onMenuItemClick = (val) => {
    // setSubMenuOpen(val);
  }

  const onContactPopupClickHandler = (val) => {
    setOpenContactPopup(val);
  }

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);


  const adminuser = localStorage.getItem("token");

  return (
    <div className="App">

      {
        (!location.pathname.includes('admin') && !location.pathname.includes('dashboard'))
          ? <MenuBar setSubMenuOpen={onMenuItemClick} onContactPopupClick={onContactPopupClickHandler} />
          : ''
      }

      {/* <div className={`wrapper-container ${(IsSubMenuOpen || openContactPopup) ? 'blur' : ''}`}> */}
      <div className={`wrapper-container`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />

          <Route path="/advisory" element={<Advisory />} />
          <Route path="/crmanalytics" element={<CRMAnalytics />} />
          <Route path="/customdevelopment" element={<CustomDevelopment />} />
          <Route path="/devops" element={<DevOps />} />
          <Route path="/implementation" element={<Implementation />} />
          <Route path="/integration" element={<Integration />} />
          <Route path="/manageservices" element={<ManageServices />} />

          <Route path="/technology" element={<Technology />} />
          <Route path="/whyshift" element={<WhyShift />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/privacypolicy" element={<PrivacyPolicy />} />


          {adminuser && <Route path="/dashboard" element={<Dashboard />} />}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/dashboard" element={<Navigate replace to="/admin" />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>

      {
        (!location.pathname.includes('admin') && !location.pathname.includes('dashboard'))
          ? <ContactPopup status={openContactPopup} onContactPopupClick={onContactPopupClickHandler} />
          : ''
      }

      {
        openContactPopup
          ? <div className='overlay' onClick={() => {
            setOpenContactPopup(false);
          }}></div>
          : ''
      }

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
