import { useEffect, useRef, useState } from "react";
import { convertEventToBase64 } from "../assets/common/functions";
import ReactTextareaAutosize from "react-textarea-autosize";

export const DashboardContent = (props) => {

    let varstore = useRef(undefined);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        varstore.data = props.data;
        setReload(ps => !ps);

    }, [props.data]);

    const saveJsonData = (heading, val) => {
        props.saveData(heading, val);
    }

    const onImageUpload = async (event, idkey, idValue, imagekey) => {
        try {
            const base64String = await convertEventToBase64(event);

            props.imageUpload(idkey, idValue, imagekey, base64String);
            // setBase64Image(base64String);
        } catch (error) {
            console.error('Error converting image to Base64:', error);
        }
    };

    const onImageSingleUpload = async (event, imagekey) => {
        try {
            const base64String = await convertEventToBase64(event);

            props.singleimageUpload(imagekey, base64String);
            // setBase64Image(base64String);
        } catch (error) {
            console.error('Error converting image to Base64:', error);
        }
    };

    const ImageBlock = (props) => {
        return <div className="image-holder" title="upload image">
            <span className="image-name">{props.imageKey}</span>

            <div className="imageWrapper">
                <img className="image" src={props.row[props.imageKey]} alt="" />
            </div>

            <label htmlFor={`file-input-${props.row.id}-${props.imageKey}`} className="custom-file-upload">
                <i className='bx bx-upload'></i> Upload <br />
                {/* <span className="file-dimension">640x724</span> */}
            </label>
            <input id={`file-input-${props.row.id}-${props.imageKey}`} type="file" accept="image/*"
                onChange={(e) => {
                    onImageUpload(e, `${props.selectedItem}.${props.selectedSubItem}.id`, props.row.id, `${props.selectedItem}.${props.selectedSubItem}.$.${props.imageKey}`)
                }}
            />
        </div>
    }

    const SimpleImageBlock = (props) => {
        return <div className="image-holder" title="upload image">
            <span className="image-name">{props.imageKey}</span>

            <div className="imageWrapper">
                <img className="image" src={props.row} alt="" />
            </div>

            <label htmlFor={`file-input-${props.imageKey}`} className="custom-file-upload">
                <i className='bx bx-upload'></i> Upload <br />
                {/* <span className="file-dimension">640x724</span> */}
            </label>
            <input id={`file-input-${props.imageKey}`} type="file" accept="image/*"
                onChange={(e) => {
                    onImageSingleUpload(e, `${props.selectedItem}.${props.selectedSubItem}`)
                }}
            />
        </div>
    }

    const ArrayTextBlock = (props) => {
        return <div className="input-area-holder">
            {
                props.row && props.row?.map((rw, ky) => {
                    return <ReactTextareaAutosize
                        key={ky}
                        spellCheck={false}
                        data-gramm={false}
                        data-gramm_editor={false}
                        data-enable-grammarly={false}
                        onChange={(e) => {
                            props.row[ky] = e.target.value;
                        }}
                        defaultValue={rw} />
                })
            }
        </div>
    }

    const HeaderKeyBlock = (props) => {
        return <div className="header">
            <label className="desciption-name">{props.textKey}</label>
            <button className="save-button"
                onClick={() => {

                    saveJsonData(`${props.selectedItem}.${props.selectedSubItem}.` + props.textKey, props.row[props.textKey]);
                }}
            > <i className='bx bx-upload'></i>Save</button>
        </div>
    }

    const TextBlock = (props) => {

        return <div className="input-area-holder">
            <ReactTextareaAutosize
                spellCheck={false}
                data-gramm={false}
                data-gramm_editor={false}
                data-enable-grammarly={false}
                onChange={(e) => {
                    props.row[props.textKey] = e.target.value;
                }}
                defaultValue={props.row[props.textKey]}
            />
        </div>
    }

    const SimpleTextBlock = (props) => {
        return <div className="input-area-holder">
            <ReactTextareaAutosize
                spellCheck={false}
                data-gramm={false}
                data-gramm_editor={false}
                data-enable-grammarly={false}
                onChange={(e) => {
                    varstore.data[props.selectedSubItem] = e.target.value;
                }}
                defaultValue={props.row}
            />
        </div>
    }

    const ObjectBlock = (props) => {
        return props.row && Object?.entries(props.row)?.map(([key, value], index) => {

            let acceptedKeys = props.acceptedKeys || [];
            if (!acceptedKeys.includes(key)) return;

            if (key === 'image' || key === 'mobile_image' || key === "imgSrc" || key === "salesforcePartnerImgSrc") {
                return <SimpleImageBlock
                    row={`${props.row[key]}`}
                    imageKey={key}
                    selectedItem={props.selectedItem}
                    selectedSubItem={`${props.selectedSubItem}.${key}`}
                />
            }

            return <div key={key} className="input-holder">
                <HeaderKeyBlock row={props.row} textKey={key} selectedItem={props.selectedItem} selectedSubItem={props.selectedSubItem} />
                {
                    typeof value === "string"
                        ? <TextBlock row={props.row} textKey={key} />
                        : ''
                }
            </div>
        })
    }
    const ArrayBlock = (props) => {
        return props.row && props.row?.map((row, key) => {

            return <div key={key} className="array-list">
                <span className="array-list-numbering">{key + 1}</span>
                {
                    Object?.entries(row)?.map(([ky, value], index) => {

                        let acceptedKeys = props.acceptedKeys || [];
                        if (!acceptedKeys?.includes(ky)) return;

                        if (ky === 'image' || ky === 'mobile_image' || ky === 'mobileimgSrc' || ky === 'imgSrc') {
                            return <ImageBlock key={ky} row={row} imageKey={ky} selectedItem={props.selectedItem} selectedSubItem={props.selectedSubItem} />
                        }
                        if (!value) return;

                        if (value && Array.isArray(value)) {
                            return <div key={ky} className="input-holder">
                                {/* <HeaderBlock row={row} textKey={ky} selectedSubItem={props.selectedSubItem} /> */}
                                <div className="header">
                                    <label className="desciption-name">{ky}</label>
                                    <button className="save-button" onClick={() => {
                                        saveJsonData(`${props.selectedItem}.${props.selectedSubItem}`, varstore.data[props.selectedSubItem]);
                                    }}> <i className='bx bx-upload'></i>Save</button>
                                </div>

                                <ArrayTextBlock row={value} />
                            </div>
                        } else {
                            return <ArrayBlockWithHeader
                                row={value}
                                textKey={ky}
                                selectedItem={props.selectedItem}
                                selectedSubItem={props.selectedSubItem} />
                        }
                    })
                }
            </div>

        })
    }

    const ObjectContainsArrayBlock = (props) => {
        return props.row && Object?.entries(props.row)?.map(([key, value], index) => {

            let acceptedKeys = props.acceptedKeys || [];
            if (!acceptedKeys.includes(key)) return;

            return <div key={key} className="input-holder">
                <HeaderKeyBlock row={props.row} textKey={key} selectedItem={props.selectedItem} selectedSubItem={props.selectedSubItem} />
                {
                    value && Array.isArray(value)
                        ? value && value?.map((rw, ky) => {

                            return <div key={ky} className="array-list">
                                <span className="array-list-numbering">{ky + 1}</span>
                                {
                                    rw && Object?.entries(rw)?.map(([k, value], index) => {


                                        let arrayAcceptedKeys = props.arrayAcceptedKeys;
                                        if (!arrayAcceptedKeys.includes(k)) return;

                                        if (k === 'image' || k === 'mobile_image' || k === "imgSrc") {

                                            return <ImageBlock key={k} row={rw} imageKey={k}
                                                selectedItem={props.selectedItem}
                                                selectedSubItem={`${props.selectedSubItem}.${key}`} />
                                        }

                                        if (!rw[k]) return;

                                        return <div key={k} className="input-holder">
                                            <TextBlock row={rw} textKey={k} />
                                        </div>
                                    })
                                }
                            </div>

                        })
                        : typeof value === "string"
                            ? <TextBlock row={props.row} textKey={key} />
                            : ''
                }
            </div>
        })
    }

    const ArrayBlockWithHeader = (props) => {
        return <div className="input-holder">

            {/* <HeaderBlock row={row} textKey={props.selectedSubItem} selectedSubItem={props.selectedSubItem} /> */}

            <div className="header">
                <label className="desciption-name">{props.textKey}</label>
                <button className="save-button" onClick={() => {
                    saveJsonData(`${props.selectedItem}.${props.selectedSubItem}`, varstore.data[props.selectedSubItem]);
                }}> <i className='bx bx-upload'></i>Save</button>
            </div>

            {

                props.row && Array.isArray(props.row)
                    ? <ArrayTextBlock row={props.row} />
                    : <SimpleTextBlock row={props.row}
                        selectedItem={props.selectedItem}
                        selectedSubItem={props.selectedSubItem}
                    // textKey={props.selectedSubItem}
                    />
            }

        </div>
    }

    return <section className="dashboard-content-container">

        <div className="block-container">

            {/* ---------------------- Home ---------------------- */}

            {
                (props.selectedItem === "home")
                    ? (props.selectedSubItem === "banner")
                        ? <ArrayBlock
                            row={varstore.data?.banner}
                            selectedItem={props.selectedItem}
                            selectedSubItem={props.selectedSubItem}
                            acceptedKeys={['image', 'mobile_image']} />
                        : (props.selectedSubItem === "aboutsection")
                            ? <ObjectBlock
                                row={varstore.data?.aboutsection}
                                selectedItem={props.selectedItem}
                                selectedSubItem={props.selectedSubItem}
                                acceptedKeys={['heading', 'paragraphContent', 'salesforcePartnerImgSrc', 'action_name']}
                            />
                            : (props.selectedSubItem === "puzzleflipsection")
                                ? <ArrayBlock
                                    row={varstore.data?.puzzleflipsection}
                                    selectedItem={props.selectedItem}
                                    selectedSubItem={props.selectedSubItem}
                                    acceptedKeys={['image', 'heading', 'content']}
                                />
                                : (props.selectedSubItem === "wecoversection")
                                    ? <ObjectContainsArrayBlock
                                        row={varstore.data?.wecoversection}
                                        selectedItem={props.selectedItem}
                                        selectedSubItem={props.selectedSubItem}
                                        acceptedKeys={['heading', 'paragraphContent', 'action_name', 'technologiesimages']}
                                        arrayAcceptedKeys={['image']}
                                    />
                                    : (props.selectedSubItem === "whyshiftsection")
                                        ? <ObjectContainsArrayBlock
                                            row={varstore.data?.whyshiftsection}
                                            selectedItem={props.selectedItem}
                                            selectedSubItem={props.selectedSubItem}
                                            acceptedKeys={['heading', 'paragraphContent', 'action_name', 'items']}
                                            arrayAcceptedKeys={['heading', 'paragraphContent', 'image']}
                                        />
                                        : ''




                    : (props.selectedItem === "about")
                        ? (props.selectedSubItem === "bannerContent")
                            ? <ArrayBlockWithHeader
                                row={varstore.data?.bannerContent}
                                textKey={'bannerContent'}
                                selectedItem={props.selectedItem}
                                selectedSubItem={props.selectedSubItem} />
                            : (props.selectedSubItem === "bannerParagraphContent")
                                ? <ArrayBlockWithHeader
                                    row={varstore.data?.bannerParagraphContent}
                                    textKey={'bannerParagraphContent'}
                                    selectedItem={props.selectedItem}
                                    selectedSubItem={props.selectedSubItem} />
                                : (props.selectedSubItem === "ourmission")
                                    ? <ObjectContainsArrayBlock
                                        row={varstore.data?.ourmission}
                                        selectedItem={props.selectedItem}
                                        selectedSubItem={props.selectedSubItem}
                                        acceptedKeys={['heading', 'content', 'platformlist', 'paragraphContent_1', 'paragraphContent_2', 'leftPieceContent']}
                                        arrayAcceptedKeys={['name', 'imgSrc']}
                                    />
                                    : (props.selectedSubItem === "ourteam")
                                        ? <ObjectBlock
                                            row={varstore.data?.ourteam}
                                            selectedItem={props.selectedItem}
                                            selectedSubItem={props.selectedSubItem}
                                            acceptedKeys={['heading', 'content', 'rightPieceContent']}
                                        />
                                        : ''



                        : (props.selectedItem === "advisory")
                            ? (props.selectedSubItem === "bannerImage")
                                ? <SimpleImageBlock row={varstore.data?.bannerImage}
                                    imageKey={props.selectedSubItem}
                                    selectedItem={props.selectedItem}
                                    selectedSubItem={props.selectedSubItem} />
                                : (props.selectedSubItem === "bannerContent")
                                    ? <ArrayBlockWithHeader
                                        row={varstore.data?.bannerContent}
                                        textKey={'bannerContent'}
                                        selectedItem={props.selectedItem}
                                        selectedSubItem={props.selectedSubItem} />
                                    : (props.selectedSubItem === "pieceDesignList")
                                        ? <ArrayBlockWithHeader
                                            row={varstore.data?.pieceDesignList}
                                            textKey={'pieceDesignList'}
                                            selectedItem={props.selectedItem}
                                            selectedSubItem={props.selectedSubItem} />
                                        : (props.selectedSubItem === "paragraphList")
                                            ? <ArrayBlockWithHeader
                                                row={varstore.data?.paragraphList}
                                                textKey={'paragraphList'}
                                                selectedItem={props.selectedItem}
                                                selectedSubItem={props.selectedSubItem} />
                                            : (props.selectedSubItem === "outcomesContent")
                                                ? <ArrayBlockWithHeader
                                                    row={varstore.data?.outcomesContent}
                                                    textKey={'outcomesContent'}
                                                    selectedItem={props.selectedItem}
                                                    selectedSubItem={props.selectedSubItem} />
                                                : ''

                            : (props.selectedItem === "crmanalytics")
                                ? (props.selectedSubItem === "bannerImage")
                                    ? <SimpleImageBlock row={varstore.data?.bannerImage}
                                        imageKey={props.selectedSubItem}
                                        selectedItem={props.selectedItem}
                                        selectedSubItem={props.selectedSubItem} />
                                    : (props.selectedSubItem === "bannerContent")
                                        ? <ArrayBlockWithHeader row={varstore.data?.bannerContent}
                                            textKey={'bannerContent'}
                                            selectedItem={props.selectedItem}
                                            selectedSubItem={props.selectedSubItem} />
                                        : (props.selectedSubItem === "pieceDesignList")
                                            ? <ArrayBlockWithHeader row={varstore.data?.pieceDesignList}
                                                textKey={'pieceDesignList'}
                                                selectedItem={props.selectedItem}
                                                selectedSubItem={props.selectedSubItem} />
                                            : (props.selectedSubItem === "paragraphList")
                                                ? <ArrayBlockWithHeader row={varstore.data?.paragraphList}
                                                    textKey={'paragraphList'}
                                                    selectedItem={props.selectedItem}
                                                    selectedSubItem={props.selectedSubItem} />
                                                : (props.selectedSubItem === "outcomesContent")
                                                    ? <ArrayBlockWithHeader row={varstore.data?.outcomesContent}
                                                        textKey={'outcomesContent'}
                                                        selectedItem={props.selectedItem}
                                                        selectedSubItem={props.selectedSubItem} />
                                                    : ''

                                : (props.selectedItem === "customdevelopment")
                                    ? (props.selectedSubItem === "bannerImage")
                                        ? <SimpleImageBlock row={varstore.data?.bannerImage}
                                            imageKey={props.selectedSubItem}
                                            selectedItem={props.selectedItem}
                                            selectedSubItem={props.selectedSubItem} />
                                        : (props.selectedSubItem === "bannerContent")
                                            ? <ArrayBlockWithHeader
                                                row={varstore.data?.bannerContent}
                                                textKey={'bannerContent'}
                                                selectedItem={props.selectedItem}
                                                selectedSubItem={props.selectedSubItem} />
                                            : (props.selectedSubItem === "pieceDesignList")
                                                ? <ArrayBlockWithHeader
                                                    row={varstore.data?.pieceDesignList}
                                                    textKey={'pieceDesignList'}
                                                    selectedItem={props.selectedItem}
                                                    selectedSubItem={props.selectedSubItem} />
                                                : (props.selectedSubItem === "paragraphList")
                                                    ? <ArrayBlockWithHeader
                                                        row={varstore.data?.paragraphList}
                                                        textKey={'paragraphList'}
                                                        selectedItem={props.selectedItem}
                                                        selectedSubItem={props.selectedSubItem} />
                                                    : (props.selectedSubItem === "outcomesContent")
                                                        ? <ArrayBlockWithHeader
                                                            row={varstore.data?.outcomesContent}
                                                            textKey={'outcomesContent'}
                                                            selectedItem={props.selectedItem}
                                                            selectedSubItem={props.selectedSubItem} />
                                                        : ''

                                    : (props.selectedItem === "devops")
                                        ? (props.selectedSubItem === "bannerImage")
                                            ? <SimpleImageBlock row={varstore.data?.bannerImage}
                                                imageKey={props.selectedSubItem}
                                                selectedItem={props.selectedItem}
                                                selectedSubItem={props.selectedSubItem} />
                                            : (props.selectedSubItem === "bannerContent")
                                                ? <ArrayBlockWithHeader
                                                    row={varstore.data?.bannerContent}
                                                    textKey={'bannerContent'}
                                                    selectedItem={props.selectedItem}
                                                    selectedSubItem={props.selectedSubItem} />
                                                : (props.selectedSubItem === "pieceDesignList")
                                                    ? <ArrayBlockWithHeader
                                                        row={varstore.data?.pieceDesignList}
                                                        textKey={'pieceDesignList'}
                                                        selectedItem={props.selectedItem}
                                                        selectedSubItem={props.selectedSubItem} />
                                                    : (props.selectedSubItem === "paragraphList")
                                                        ? <ArrayBlockWithHeader
                                                            row={varstore.data?.paragraphList}
                                                            textKey={'paragraphList'}
                                                            selectedItem={props.selectedItem}
                                                            selectedSubItem={props.selectedSubItem} />
                                                        : (props.selectedSubItem === "outcomesContent")
                                                            ? <ArrayBlockWithHeader
                                                                row={varstore.data?.outcomesContent}
                                                                textKey={'outcomesContent'}
                                                                selectedItem={props.selectedItem}
                                                                selectedSubItem={props.selectedSubItem} />
                                                            : ''
                                        : (props.selectedItem === "implementation")
                                            ? (props.selectedSubItem === "bannerImage")
                                                ? <SimpleImageBlock row={varstore.data?.bannerImage}
                                                    imageKey={props.selectedSubItem}
                                                    selectedItem={props.selectedItem}
                                                    selectedSubItem={props.selectedSubItem} />
                                                : (props.selectedSubItem === "bannerContent")
                                                    ? <ArrayBlockWithHeader
                                                        row={varstore.data?.bannerContent}
                                                        textKey={'bannerContent'}
                                                        selectedItem={props.selectedItem}
                                                        selectedSubItem={props.selectedSubItem} />
                                                    : (props.selectedSubItem === "pieceDesignList")
                                                        ? <ArrayBlockWithHeader
                                                            row={varstore.data?.pieceDesignList}
                                                            textKey={'pieceDesignList'}
                                                            selectedItem={props.selectedItem}
                                                            selectedSubItem={props.selectedSubItem} />
                                                        : (props.selectedSubItem === "paragraphList")
                                                            ? <ArrayBlockWithHeader
                                                                row={varstore.data?.paragraphList}
                                                                textKey={'paragraphList'}
                                                                selectedItem={props.selectedItem}
                                                                selectedSubItem={props.selectedSubItem} />
                                                            : (props.selectedSubItem === "outcomesContent")
                                                                ? <ArrayBlockWithHeader
                                                                    row={varstore.data?.outcomesContent}
                                                                    textKey={'outcomesContent'}
                                                                    selectedItem={props.selectedItem}
                                                                    selectedSubItem={props.selectedSubItem} />
                                                                : ''
                                            : (props.selectedItem === "integration")
                                                ? (props.selectedSubItem === "bannerImage")
                                                    ? <SimpleImageBlock row={varstore.data?.bannerImage}
                                                        imageKey={props.selectedSubItem}
                                                        selectedItem={props.selectedItem}
                                                        selectedSubItem={props.selectedSubItem} />
                                                    : (props.selectedSubItem === "bannerContent")
                                                        ? <ArrayBlockWithHeader
                                                            row={varstore.data?.bannerContent}
                                                            textKey={'bannerContent'}
                                                            selectedItem={props.selectedItem}
                                                            selectedSubItem={props.selectedSubItem} />
                                                        : (props.selectedSubItem === "pieceDesignList")
                                                            ? <ArrayBlockWithHeader
                                                                row={varstore.data?.pieceDesignList}
                                                                textKey={'pieceDesignList'}
                                                                selectedItem={props.selectedItem}
                                                                selectedSubItem={props.selectedSubItem} />
                                                            : (props.selectedSubItem === "paragraphList")
                                                                ? <ArrayBlockWithHeader
                                                                    row={varstore.data?.paragraphList}
                                                                    textKey={'paragraphList'}
                                                                    selectedItem={props.selectedItem}
                                                                    selectedSubItem={props.selectedSubItem} />
                                                                : (props.selectedSubItem === "outcomesContent")
                                                                    ? <ArrayBlockWithHeader
                                                                        row={varstore.data?.outcomesContent}
                                                                        textKey={'outcomesContent'}
                                                                        selectedItem={props.selectedItem}
                                                                        selectedSubItem={props.selectedSubItem} />
                                                                    : ''
                                                : (props.selectedItem === "managedservices")
                                                    ? (props.selectedSubItem === "bannerImage")
                                                        ? <SimpleImageBlock row={varstore.data?.bannerImage}
                                                            imageKey={props.selectedSubItem}
                                                            selectedItem={props.selectedItem}
                                                            selectedSubItem={props.selectedSubItem} />
                                                        : (props.selectedSubItem === "bannerContent")
                                                            ? <ArrayBlockWithHeader
                                                                row={varstore.data?.bannerContent}
                                                                textKey={'bannerContent'}
                                                                selectedItem={props.selectedItem}
                                                                selectedSubItem={props.selectedSubItem} />
                                                            : (props.selectedSubItem === "pieceDesignList")
                                                                ? <ArrayBlockWithHeader
                                                                    row={varstore.data?.pieceDesignList}
                                                                    textKey={'pieceDesignList'}
                                                                    selectedItem={props.selectedItem}
                                                                    selectedSubItem={props.selectedSubItem} />
                                                                : (props.selectedSubItem === "paragraphList")
                                                                    ? <ArrayBlockWithHeader
                                                                        row={varstore.data?.paragraphList}
                                                                        textKey={'paragraphList'}
                                                                        selectedItem={props.selectedItem}
                                                                        selectedSubItem={props.selectedSubItem} />
                                                                    : (props.selectedSubItem === "outcomesContent")
                                                                        ? <ArrayBlockWithHeader
                                                                            row={varstore.data?.outcomesContent}
                                                                            textKey={'outcomesContent'}
                                                                            selectedItem={props.selectedItem}
                                                                            selectedSubItem={props.selectedSubItem} />
                                                                        : ''
                                                    : (props.selectedItem === "whyshift")
                                                        ? (props.selectedSubItem === "bannerContent")
                                                            ? <ArrayBlockWithHeader
                                                                row={varstore.data?.bannerContent}
                                                                textKey={'bannerContent'}
                                                                selectedItem={props.selectedItem}
                                                                selectedSubItem={props.selectedSubItem} />
                                                            : (props.selectedSubItem === "bannerParagraphContent")
                                                                ? <ArrayBlockWithHeader
                                                                    row={varstore.data?.bannerParagraphContent}
                                                                    textKey={'bannerParagraphContent'}
                                                                    selectedItem={props.selectedItem}
                                                                    selectedSubItem={props.selectedSubItem} />
                                                                : (props.selectedSubItem === "speedtovalue")
                                                                    ? <ObjectBlock
                                                                        row={varstore.data?.speedtovalue}
                                                                        selectedItem={props.selectedItem}
                                                                        selectedSubItem={props.selectedSubItem}
                                                                        acceptedKeys={['name', 'description', 'imgSrc']}
                                                                    />
                                                                    : (props.selectedSubItem === "uncompromisingquality")
                                                                        ? <ObjectBlock
                                                                            row={varstore.data?.uncompromisingquality}
                                                                            selectedItem={props.selectedItem}
                                                                            selectedSubItem={props.selectedSubItem}
                                                                            acceptedKeys={['name', 'description', 'imgSrc']}
                                                                        />
                                                                        : (props.selectedSubItem === "outcomeorientedengagement")
                                                                            ? <ObjectBlock
                                                                                row={varstore.data?.outcomeorientedengagement}
                                                                                selectedItem={props.selectedItem}
                                                                                selectedSubItem={props.selectedSubItem}
                                                                                acceptedKeys={['name', 'description', 'imgSrc']}
                                                                            />
                                                                            : (props.selectedSubItem === "competitivepricing")
                                                                                ? <ObjectBlock
                                                                                    row={varstore.data?.competitivepricing}
                                                                                    selectedItem={props.selectedItem}
                                                                                    selectedSubItem={props.selectedSubItem}
                                                                                    acceptedKeys={['name', 'description', 'imgSrc']}
                                                                                />
                                                                                : (props.selectedSubItem === "choosingyugashift")
                                                                                    ? <ArrayBlockWithHeader
                                                                                        row={varstore.data?.choosingyugashift}
                                                                                        textKey={'choosingyugashift'}
                                                                                        selectedItem={props.selectedItem}
                                                                                        selectedSubItem={props.selectedSubItem} />
                                                                                    : ''



                                                        : (props.selectedItem === "technology")
                                                            ? (props.selectedSubItem === "bannerContent")
                                                                ? <ArrayBlockWithHeader
                                                                    row={varstore.data?.bannerContent}
                                                                    textKey={'bannerContent'}
                                                                    selectedItem={props.selectedItem}
                                                                    selectedSubItem={props.selectedSubItem} />
                                                                : (props.selectedSubItem === "bannerParagraphContent")
                                                                    ? <ArrayBlockWithHeader
                                                                        row={varstore.data?.bannerParagraphContent}
                                                                        textKey={'bannerParagraphContent'}
                                                                        selectedItem={props.selectedItem}
                                                                        selectedSubItem={props.selectedSubItem} />
                                                                    : (props.selectedSubItem === "technologyList")
                                                                        ? <ArrayBlock
                                                                            row={varstore.data?.technologyList}
                                                                            selectedItem={props.selectedItem}
                                                                            selectedSubItem={props.selectedSubItem}
                                                                            acceptedKeys={['name', 'imgSrc', 'mobileimgSrc', 'content']}
                                                                        />
                                                                        : ''

                                                            : (props.selectedItem === "contact")
                                                                ? (props.selectedSubItem === "description")
                                                                    ? <ArrayBlockWithHeader
                                                                        row={varstore.data?.description}
                                                                        textKey={'Description'}
                                                                        selectedItem={props.selectedItem}
                                                                        selectedSubItem={props.selectedSubItem} />
                                                                    : (props.selectedSubItem === "address")
                                                                        ? <ArrayBlockWithHeader
                                                                            row={varstore.data?.address}
                                                                            textKey={'Address'}
                                                                            selectedItem={props.selectedItem}
                                                                            selectedSubItem={props.selectedSubItem} />
                                                                        : (props.selectedSubItem === "email")
                                                                            ? <ArrayBlockWithHeader
                                                                                row={varstore.data?.email}
                                                                                textKey={'Email'}
                                                                                selectedItem={props.selectedItem}
                                                                                selectedSubItem={props.selectedSubItem} />
                                                                            : (props.selectedSubItem === "phno")
                                                                                ? <ArrayBlock
                                                                                    row={varstore.data?.phno}
                                                                                    selectedItem={props.selectedItem}
                                                                                    selectedSubItem={props.selectedSubItem}
                                                                                    acceptedKeys={['number', 'displayNum']}
                                                                                />
                                                                                : ''




                                                                : (props.selectedItem === "footer")
                                                                    ? (props.selectedSubItem === "location")
                                                                        ? <ArrayBlockWithHeader
                                                                            row={varstore.data?.location}
                                                                            textKey={'Location'}
                                                                            selectedItem={props.selectedItem}
                                                                            selectedSubItem={props.selectedSubItem} />
                                                                        : (props.selectedSubItem === "address")
                                                                            ? <ArrayBlockWithHeader
                                                                                row={varstore.data?.address}
                                                                                textKey={'Address'}
                                                                                selectedItem={props.selectedItem}
                                                                                selectedSubItem={props.selectedSubItem} />
                                                                            : (props.selectedSubItem === "email")
                                                                                ? <ArrayBlockWithHeader
                                                                                    row={varstore.data?.email}
                                                                                    textKey={'Email'}
                                                                                    selectedItem={props.selectedItem}
                                                                                    selectedSubItem={props.selectedSubItem} />
                                                                                : (props.selectedSubItem === "phno")
                                                                                    ? <ArrayBlock
                                                                                        row={varstore.data?.phno}
                                                                                        selectedItem={props.selectedItem}
                                                                                        selectedSubItem={props.selectedSubItem}
                                                                                        acceptedKeys={['number', 'displayNum']}
                                                                                    />
                                                                                    : ''
                                                                    : ''
            }

        </div>
    </section>
}