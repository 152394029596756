export const BASE_URL = 'https://yugashift.com';
export const IMAGE_URL = 'https://yugashift.com/images/';


// export const API_BASE_URL = 'http://localhost:3010';
export const API_BASE_URL = 'https://backend.yugashift.com';

export const JSON_DATA = {
    "home": {
        "banner": [
            {
                "id": 1,
                "image": "https://yugashift.com/images/banner_image_1.png",
                "description": "Supercharge Your Success with Salesforce Einstein AI"
            },
            {
                "id": 2,
                "image": "https://yugashift.com/images/banner_image_2.png",
                "mobile_image": "https://yugashift.com/images/mobile_view_banner_image_2.png",
                "description": "Unlock Engaging Stakeholder Experiences Through Intuitive AI Solutions and Generative AI",
                "action_name": "Learn More",
                "action_target": "/crmanalytics"
            },
            {
                "id": 3,
                "image": "https://yugashift.com/images/banner_image_3.png",
                "mobile_image": "https://yugashift.com/images/mobile_view_banner_image_3.png",
                "description": "Achieve Certainty in Your Investments with Outcome-Based Services",
                "action_name": "Learn More",
                "action_target": "/whyshift"
            },
            {
                "id": 4,
                "image": "https://yugashift.com/images/banner_image_4.png",
                "mobile_image": "https://yugashift.com/images/mobile_view_banner_image_4.png",
                "description": "Welcome to an Integrated and Connected Future Powered by AI and Data",
                "action_name": "Learn More",
                "action_target": "/integration"
            }
        ],
        "aboutsection": {
            "heading": "Welcome to Yuga Shift",
            "paragraphContent": "Unlock the full potential of your business with Yuga Shift. We are a leading provider of technology solutions, specializing in empowering businesses to leverage the power of industry-leading platforms such as Salesforce, ServiceNow, and HubSpot. Our expertise spans various platforms and services, including Salesforce (Sales Cloud, Service Cloud, Marketing Cloud, Experience Cloud, Revenue Cloud/CPQ), ServiceNow, and HubSpot.",
            "salesforcePartnerImgSrc": 'https://yugashift.com/images/salesforce_partner.png',
            "action_name": "Learn More",
            "action_target": "/about"
        },
        "puzzleflipsection": [{
            "id": 1,
            "image": "https://yugashift.com/images/logo.svg",
            "content": [
                "Our team of experienced consultants work closely with your organization to assess your needs, identify growth opportunities, and develop a comprehensive roadmap tailored to your business objectives."
            ],
            "action_target": "/"
        }, {
            "id": 2,
            "heading": [
                "SaaS ",
                "<span>Advisory</span>",
                " for informed growth decisions"
            ],
            "content": [
                "At advisory, our advisory services are designed to provide strategic guidance and expertise to help you make informed decisions about your sales, marketing, and your composite service technology landscape."
            ],
            "action_target": "/advisory"
        }, {
            "id": 3,
            "heading": [
                "Smooth, tailored ",
                "<span>implementations</span>",
                " for platform success"
            ],
            "content": [
                "Efficient and seamless implementations are at the core of our service offerings. Our dedicated team of implementation specialists brings a wealth of experience in deploying and configuring platforms like those in Salesforce  HubSpot and ServiceNow."
            ],
            "action_target": "/implementation"
        }, {
            "id": 4,
            "heading": [
                "Enhanced support with our ",
                "<span>Managed Services</span>"
            ],
            "content": [
                "Our managed services are designed to provide ongoing support and maintenance for your SaaS platforms. We understand that constant optimization and proactive management are key to maximizing your complete investment."
            ],
            "action_target": "/manageservices"
        }, {
            "id": 5,
            "heading": [
                "Tailored solutions for unique needs with ",
                "<span>Custom Development</span>"
            ],
            "content": [
                "Sometimes, out-of-the-box solutions are not enough to meet your unique business requirements. That's where our custom development services come in."
            ],
            "action_target": "/customdevelopment"
        }, {
            "id": 6,
            "heading": [
                "Efficient ",
                "<span>Integration</span>",
                " for unified consumer experience"
            ],
            "content": [
                "Seamless integration of your sales and marketing platforms is essential for achieving a unified view of your customers and optimizing business processes."
            ],
            "action_target": "/integration"
        }, {
            "id": 7,
            "heading": [
                "<span>DevOps</span>",
                " empowers rapid, reliable software delivery"
            ],
            "content": [
                "In today's rapidly changing technology landscape, agile development practices and continuous deployment are critical for staying ahead of the curve."
            ],
            "action_target": "/devops"
        }, {
            "id": 8,
            "heading": [
                "Salesforce Analytics ",
                "<span>Integration</span>",
                " & AI: Actionable Insights"
            ],
            "content": [
                "Salesforce Analytics and AI-Powered Insights:",
                "<br />",
                "<br />",
                "At Yuga Shift, we understand the importance of data-driven decision-making in today's business landscape."
            ],
            "action_target": "/technology"
        }],
        "wecoversection": {
            "heading": "We Cover...",
            "paragraphContent": "At Yuga Shift, we are committed to leveraging our expertise in these technology platforms to help you navigate your digital journey. Contact us today to discover how our specialized solutions and deep knowledge of these platforms can transform your business and drive success in today's rapidly evolving digital landscape.",
            "action_name": "Learn More",
            "action_target": "/advisory",
            "technologiesimages": [
                {
                    "id": 1,
                    "image": "https://yugashift.com/images/salesforce.png"
                },
                {
                    "id": 2,
                    "image": "https://yugashift.com/images/home_hubspot.png"
                },
                {
                    "id": 3,
                    "image": "https://yugashift.com/images/home_servicenow.png"
                }
            ]
        },
        "whyshiftsection": {
            "heading": "Why Yuga Shift?",
            "paragraphContent": "At Yuga Shift, we go above and beyond to provide unparalleled value to our customers. Here's why choosing our company is the best decision you can make",
            "items": [
                {
                    "id": 1,
                    "heading": "Speed to Value",
                    "paragraphContent": "We understand that time is of the essence in today's fast-paced business environment.",
                    "image": "https://yugashift.com/images/home_why_shift_1.png"
                },
                {
                    "id": 2,
                    "heading": "Uncompromising Quality",
                    "paragraphContent": "We take immense pride in our technical expertise and commitment to excellence.",
                    "image": "https://yugashift.com/images/home_why_shift_2.png"
                },
                {
                    "id": 3,
                    "heading": "Outcome-Oriented",
                    "paragraphContent": "We understand that time is of the essence in today's fast-paced business environment.",
                    "image": "https://yugashift.com/images/home_why_shift_3.png"
                },
                {
                    "id": 4,
                    "heading": "Uncompromised Value",
                    "paragraphContent": "We understand that time is of the essence in today's fast-paced business environment.",
                    "image": "https://yugashift.com/images/home_why_shift_4.png"
                }
            ],
            "action_name": "Learn More",
            "action_target": "/whyshift"
        }
    },
    "about": {
        "bannerContent": [
            "Empowering Business Transformation through Refined ",
            "<span>Cycles</span>",
            " of Innovation"
        ],
        "bannerParagraphContent": [
            "At Yuga Shift, we are dedicated to helping businesses harness the forces of transformation and embrace a journey of continuous improvement. The name ",
            "<span>Yuga Shift</span>",
            " is inspired by the Sanskrit word 'Yuga,' which represents the concept of repeating cycles with refinement in each iteration. We believe that true growth & success come from embracing change and evolving with each cycle."
        ],
        "ourmission": {
            "heading": "Our mission",
            "content": "To empower your business with cutting-edge technology solutions and provide the guidance needed to navigate the complex landscape of digital transformation with a focus on industry-leading platforms like:",
            "platformlist": [
                {
                    "id": "1",
                    "name": "sales cloud",
                    "imgSrc": "https://yugashift.com/images/salesforce.png"
                },
                {
                    "id": "2",
                    "name": "marketing cloud",
                    "imgSrc": "https://yugashift.com/images/salesforce.png"
                },
                {
                    "id": "3",
                    "name": "experience cloud",
                    "imgSrc": "https://yugashift.com/images/salesforce.png"
                },
                {
                    "id": "4",
                    "name": "einstein analytics",
                    "imgSrc": "https://yugashift.com/images/salesforce.png"
                },
                {
                    "id": "5",
                    "name": "service cloud",
                    "imgSrc": "https://yugashift.com/images/salesforce.png"
                },
                {
                    "id": "6",
                    "name": "data cloud",
                    "imgSrc": "https://yugashift.com/images/salesforce.png"
                },
                {
                    "id": "7",
                    "name": "",
                    "imgSrc": "https://yugashift.com/images/servicenow.png"
                },
                {
                    "id": "8",
                    "name": "",
                    "imgSrc": "https://yugashift.com/images/hubspot.png"
                }
            ],
            "paragraphContent_1": "At Yuga Shift, we understand that technology alone is not the answer. It's about finding the right balance between innovation and refinement, leveraging the power of digital solutions while staying true to your unique business objectives.",
            "paragraphContent_2": "We collaborate with our clients, taking the time to understand their specific needs, challenges, and aspirations. Our team of experts combines technical prowess with a deep understanding of sales, marketing, customer service, and IT operations to deliver holistic solutions that drive sustainable growth.",
            "leftPieceContent": "Just as the cycles in nature refine and elevate, we believe in constantly refining our approach and adapting to the changing business landscape. Our agile way of working ensures that we embrace innovation, yet remain focused on delivering value in shorter cycles. This approach enables our clients to realize returns on their investments in a matter of months, rather than waiting years for transformation efforts to bear fruit."
        },
        "ourteam": {
            "heading": "Our Team",
            "content": "At Yuga Shift, we are more than just a technology solutions provider. We are your trusted partner in the journey of business transformation. Our commitment to your success goes beyond implementing and customizing platforms. We offer a comprehensive suite of services, including advisory, implementations, managed services, custom development, integration, and DevOps, to support you at every stage of your digital evolution.",
            "rightPieceContent": "Join us on this transformative journey and experience the power of Yuga Shift. Let us empower your business to embrace change, refine your processes, and achieve new heights of success. Contact us today to learn more about how Yuga Shift can help you navigate the forces of transformation and thrive in the digital age."
        }
    },
    "advisory": {
        "bannerImage": "https://yugashift.com/images/advisory.png",
        "bannerContent": [
            "We provide strategic solutions to help you ",
            "<span>navigate</span>",
            " your sales, marketing, & service technology landscape."
        ],
        "pieceDesignList": [
            "Implementation of detailed consultations via a collaborative approach",
            "Execution of in-depth research of your needs and requirements",
            "Comprehensive understanding of business goals & challenges",
            "Construction of tailored roadmaps that align with your unique needs",
            "Development of refined strategies based on real-time feedback"
        ],
        "paragraphList": [
            "Our team of experienced consultants work closely with your organization to assess your needs, identify growth opportunities, and develop a comprehensive roadmap tailored to your business objectives.",
            "Whether you need assistance with platform selection, process optimization, or digital transformation, our advisory services empower you to confidently navigate the ever-evolving world of SaaS platforms."
        ],
        "outcomesContent": "The outcome of our advisory services is a clear and actionable roadmap that guides you towards optimal technology solutions and long-term success. With Yuga Shift as your trusted advisor, you can confidently navigate the complexities of digital transformation and maximize the value of your technology investments."
    },
    "crmanalytics": {
        "bannerImage": "https://yugashift.com/images/crmanalytics.png",
        "bannerContent": [
            "At Yuga Shift, we understand the importance of ",
            "<span>data-driven</span>",
            " decision-making in today's business landscape."
        ],
        "pieceDesignList": [
            "Implementation of detailed consultations via a collaborative approach",
            "Execution of in-depth research of your needs and requirements",
            "Comprehensive understanding of business goals & challenges",
            "Construction of tailored roadmaps that align with your unique needs",
            "Development of refined strategies based on real-time feedback"
        ],
        "paragraphList": [
            "We offer expertise in Salesforce Analytics, including CRM and Tableau, to empower you with actionable insights. Our team of data experts leverages the power of Einstein, Salesforce's AI technology, to uncover hidden patterns, predict trends, and drive intelligent business decisions. We also specialize in building custom Machine Learning models tailored to your specific needs, allowing you to unlock the full potential of your data.",
            "We believe in choosing the right tool that fits within your budget and aligns with your use case. Whether you require simple reports and dashboards or more sophisticated data visualizations, we have the skills and expertise to deliver solutions that meet your unique requirements. Our data professionals work closely with you to understand your business goals, identify key metrics, and design analytical solutions that provide meaningful insights. With YugaShift, you can harness the power of AI and advanced analytics to gain a competitive edge and make data-driven decisions with confidence.",
            "Let us help you navigate the complex world of data analytics and AI technologies. Contact us today to explore how our expertise in Salesforce Analytics, Einstein, and custom Machine Learning models can empower your business with actionable insights and drive success."
        ],
        "outcomesContent": "The outcome of our CRM Analytics service is a comprehensive view of your customer data, enabling you to understand customer behaviors, preferences, and pain points. Armed with these valuable insights, you can optimize your marketing campaigns, tailor your sales strategies, and deliver exceptional customer experiences. With Yuga Shift's CRM Analytics expertise, you can make informed decisions, achieve higher customer satisfaction, and drive business growth in today's data-driven world."
    },
    "customdevelopment": {
        "bannerImage": "https://yugashift.com/images/customdevelopment.png",
        "bannerContent": [
            "We possess a deep understanding of the SaaS platforms we work with & can ",
            "<span>extend their capabilities</span>",
            " to align with your specific needs."
        ],
        "pieceDesignList": [
            "Implementation of detailed consultations via a collaborative approach",
            "Execution of in-depth research of your needs and requirements",
            "Comprehensive understanding of business goals & challenges",
            "Construction of tailored roadmaps that align with your unique needs",
            "Development of refined strategies based on real-time feedback"
        ],
        "paragraphList": [
            "Sometimes, out-of-the-box solutions are not enough to meet your unique business requirements. Whether you need custom workflows, integrations, or complex data analytics, our developers collaborate closely with you to deliver tailored solutions that drive efficiency, enhance productivity, and differentiate your business in the market."
        ],
        "outcomesContent": "The outcome of our custom development services is a suite of applications and tools that address your specific pain points, improve operational efficiency, and foster innovation within your organization. With Yuga Shift's custom development expertise, you can achieve a competitive edge and streamline your business processes with precision."
    },
    "devops": {
        "bannerImage": "https://yugashift.com/images/devops.png",
        "bannerContent": [
            "Our DevOps services combine ",
            "<span>development</span>",
            ", operations, & quality assurance to enable faster, more reliable software delivery."
        ],
        "pieceDesignList": [
            "Implementation of detailed consultations via a collaborative approach",
            "Execution of in-depth research of your needs and requirements",
            "Comprehensive understanding of business goals & challenges",
            "Construction of tailored roadmaps that align with your unique needs",
            "Development of refined strategies based on real-time feedback"
        ],
        "paragraphList": [
            "In today's rapidly changing technology landscape, agile development practices and continuous deployment are critical for staying ahead of the curve. With our DevOps expertise, we help you adopt best practices, automate workflows, and implement a culture of collaboration and innovation. By fostering a DevOps mindset within your organization, you can accelerate time-to-market, enhance software quality, and improve overall business agility."
        ],
        "outcomesContent": "The outcome of our DevOps services is enhanced agility, improved software quality, and reduced time-to-market for your applications and services. With Yuga Shift's DevOps approach, your organization can achieve increased responsiveness to market demands, seize new opportunities, and drive business growth."
    },
    "implementation": {
        "bannerImage": "https://yugashift.com/images/implementation.png",
        "bannerContent": [
            "Efficient and seamless ",
            "<span>implementations</span>",
            " are at the core of our service offerings"
        ],
        "pieceDesignList": [
            "Our methodology revolves around efficiency, precision, & user adoption",
            "Detailed analysis of your business processes & requirements",
            "We follow industry best practices and ensure a seamless deployment",
            "Rigorous testing & quality assurance guarantee the platform's stability",
            "We prioritize user training and change management"
        ],
        "paragraphList": [
            "Our dedicated team of implementation specialists brings a wealth of experience in deploying and configuring platforms like Salesforce Sales Cloud, HubSpot, Salesforce Service Cloud, and ServiceNow.",
            "We work closely with your team to understand your unique requirements and tailor the solution to meet your business objectives. With meticulous planning, thorough testing, and a focus on user adoption, we ensure a smooth transition and empower you to leverage the full potential of these platforms from day one."
        ],
        "outcomesContent": "The outcome of our implementation services is a fully customized and optimized platform that fits your specific needs. With Yuga Shift's expertise, you can experience a smooth transition, enhanced productivity, and accelerated return on investment."
    },
    "integration": {
        "bannerImage": "https://yugashift.com/images/integration.png",
        "bannerContent": [
            "Seamless ",
            "<span>integration</span>",
            " of your sales and marketing platforms, achieving a unified view of your customers"
        ],
        "pieceDesignList": [
            "Implementation of detailed consultations via a collaborative approach",
            "Execution of in-depth research of your needs and requirements",
            "Comprehensive understanding of business goals & challenges",
            "Construction of tailored roadmaps that align with your unique needs",
            "Development of refined strategies based on real-time feedback"
        ],
        "paragraphList": [
            "Our integration services enable you to connect disparate systems, streamline data flows, and eliminate silos. Whether it's integrating Salesforce, HubSpot, ServiceNow or establishing seamless data exchange between these systems and your ERP or other bespoke systems, we have the expertise to design and implement robust integration solutions that drive operational efficiency and deliver a unified customer experience."
        ],
        "outcomesContent": "The outcome of our integration services is a harmonized technology environment that empowers your business with real-time insights and enhanced operational efficiency. With Yuga Shift's integration expertise, you can break down data silos, achieve data-driven decision-making, and elevate your customer interactions."
    },
    "managedservices": {
        "bannerImage": "https://yugashift.com/images/managedservices.png",
        "bannerContent": [
            "Our managed services are designed to provide ",
            "<span>ongoing support</span>",
            " and maintenance for your SaaS platforms"
        ],
        "pieceDesignList": [
            "Founded on proactive support, continuous optimization, with a focus on success",
            "We diligently monitor the performance and health of your platforms",
            "Identification and resolution of issues before they impact your operations",
            "Provision of regular updates & actionable insights to enhance system performance",
            "We ensure your platforms health; its stability, scalability, and security"
        ],
        "paragraphList": [
            "We understand that continuous optimization and proactive management are key to maximizing your investment. Our team of experts offers a range of managed services, including system administration, performance monitoring, security enhancements, and user training.",
            "With our dedicated support, you can focus on your core business while we take care of your platform's health, ensuring its stability, scalability, and security."
        ],
        "outcomesContent": "With Yuga Shift managing your technology platforms, you can experience reduced downtime, increased efficiency, and optimized costs. Our commitment to proactive management ensures that your business stays agile, competitive, and equipped to meet any and all future challenges."
    },
    "whyshift": {
        "bannerContent": [
            "Empowering Your Business: Why Choose ",
            "<span>Yuga Shift</span>",
            " for Your Technology Solutions?"
        ],
        "bannerParagraphContent": "Contact us today to discover how our specialized solutions and deep knowledge of these platforms can transform your business and drive success in today's rapidly evolving digital landscape.",
        "speedtovalue": {
            "name": "Speed to Value",
            "description": "We understand that time is of the essence in today's fast-paced business environment. That's why we embrace an agile approach that enables us to deliver results quickly and efficiently. Unlike lengthy and costly transformation projects, our agile methodology ensures that you start seeing a return on your investment within a matter of months. By breaking down projects into smaller, manageable increments, we help you achieve tangible results and drive continuous value.",
            "imgSrc": "https://yugashift.com/images/whyshift_1.png"
        },
        "uncompromisingquality": {
            "name": "Uncompromising Quality",
            "description": "We take immense pride in our technical expertise and commitment to excellence. Our team of seasoned professionals follows a rigorous delivery methodology that prioritizes quality at every stage. By adhering to best practices and leveraging our deep domain knowledge, we ensure significantly lower defect rates, minimizing disruptions and saving costs for our customers. With us, you can expect top-notch solutions that are reliable, secure, and tailored to your specific needs.",
            "imgSrc": "https://yugashift.com/images/whyshift_2.png"
        },
        "outcomeorientedengagement": {
            "name": "Outcome-Oriented Engagement",
            "description": "We believe in sharing the risks and rewards with our customers. In addition to traditional engagement models like Time and Material (T&M), we offer outcome-based models that demonstrate our dedication to delivering measurable results. Whether it's a fixed bid project or performance-based agreements, we align our incentives with your desired outcomes. This approach not only fosters trust and collaboration but also ensures that our goals are fully aligned with yours.",
            "imgSrc": "https://yugashift.com/images/whyshift_3.png"
        },
        "competitivepricing": {
            "name": "Competitive Pricing, Uncompromised Value",
            "description": "While we take pride in the quality and value of our services, we understand the importance of offering reasonable pricing. Our services are competitively priced, providing you with a compelling cost-to-value ratio. We believe that exceptional service shouldn't come with exorbitant price tags. With us, you can expect a fair and transparent pricing structure that reflects the value you receive.",
            "imgSrc": "https://yugashift.com/images/whyshift_4.png"
        },
        "choosingyugashift": "Choosing YugaShift means investing in a partner who is committed to your success. We strive to deliver unparalleled speed to value, uncompromising quality and outcome-based engagement models that align with your goals. Our competitive pricing ensures that you receive exceptional value without sacrificing your budget.  Experience the difference of working with a company that prioritizes your success and delivers results that exceed your expectations. Contact us today to embark on a transformative journey with YugaShift."
    },
    "technology": {
        "bannerContent": [
            "We are committed to leveraging our expertise in ",
            "<span>technology</span>",
            " platforms to help  navigate your digital journey."
        ],
        "bannerParagraphContent": "Contact us today to discover how our specialized solutions and deep knowledge of these platforms can transform your business and drive success in today's rapidly evolving digital landscape.",
        "technologyList": [
            {
                "id": "1",
                "name": "sales cloud",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Welcome to our Salesforce Sales Cloud page! At YugaShift, we specialize in leveraging the power of Salesforce Sales Cloud to supercharge your sales processes and drive revenue growth. With our deep expertise in this industry-leading CRM platform, we help businesses streamline their sales operations, enhance customer engagement, and optimize their sales cycles. From lead management to opportunity tracking, forecasting, and reporting, our team of certified Salesforce experts can tailor Sales Cloud to your specific business needs, empowering your sales team to close deals faster and exceed targets. Discover how our Sales Cloud solutions can transform your sales organization and drive sustainable business growth."
            },
            {
                "id": "2",
                "name": "marketing cloud",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Welcome to our Salesforce Marketing Cloud page! In today's digital world, personalized and targeted marketing campaigns are essential for engaging customers and driving conversions. With Salesforce Marketing Cloud, we enable businesses to create, automate, and optimize their marketing strategies across multiple channels. Our team of marketing automation experts leverages the power of Marketing Cloud's advanced capabilities, including email marketing, social media management, journey orchestration, and analytics. By harnessing the full potential of Marketing Cloud, we help you deliver personalized, data-driven experiences that resonate with your audience and drive measurable results."
            },
            {
                "id": "3",
                "name": "experience cloud",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Welcome to our Salesforce Experience Cloud page! Creating exceptional digital experiences for your customers, partners, and employees is crucial in today's connected world. With Salesforce Experience Cloud, we empower you to build customized, branded portals that provide a seamless and engaging user experience. Our team of Experience Cloud specialists helps you leverage the power of this platform to create self-service communities, partner portals, knowledge bases, and more. Whether you need to improve customer self-service, enhance partner collaboration, or boost employee productivity, our Experience Cloud solutions can transform your digital presence and drive meaningful connections."
            },
            {
                "id": "4",
                "name": "service cloud",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Welcome to our Salesforce Service Cloud page! Exceptional customer service is at the heart of every successful business. With Salesforce Service Cloud, we help you deliver personalized and seamless customer experiences that drive satisfaction and loyalty. Our team of Service Cloud experts understands the critical importance of efficient case management, omnichannel support, and self-service capabilities. By leveraging Service Cloud's robust features, we empower your customer service teams to provide prompt resolutions, proactive support, and a 360-degree view of your customers. Unlock the full potential of Service Cloud with our tailored solutions and take your customer service to new heights."
            },
            {
                "id": "5",
                "name": "revenue cloud",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Welcome to our Salesforce Revenue Cloud (CPQ) page! Streamlining the quoting and pricing process is essential for driving revenue and ensuring sales efficiency. With Salesforce Revenue Cloud (formerly known as CPQ), we help you automate and optimize your configure, price, quote processes. Our team of CPQ experts leverages the power of Revenue Cloud's advanced capabilities to provide accurate quotes, streamline approval workflows, and optimize pricing strategies. From complex pricing configurations to guided selling, our tailored CPQ solutions ensure that your sales teams have the tools they need to close deals faster, increase revenue, and improve profitability."
            },
            {
                "id": "6",
                "name": "field service lightning",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Deliver exceptional on-site service experiences with Salesforce Field Service Lightning. Our Field Service Lightning specialists help you optimize your field service operations, from appointment scheduling to mobile workforce management. Ensure efficient dispatching, real-time communication, and superior customer service in the field."
            },
            {
                "id": "7",
                "name": "einstein analytics",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Welcome to our Salesforce Sales Cloud page! At YugaShift, we specialize in leveraging the power of Salesforce Sales Cloud to supercharge your sales processes and drive revenue growth. With our deep expertise in this industry-leading CRM platform, we help businesses streamline their sales operations, enhance customer engagement, and optimize their sales cycles. From lead management to opportunity tracking, forecasting, and reporting, our team of certified Salesforce experts can tailor Sales Cloud to your specific business needs, empowering your sales team to close deals faster and exceed targets. Discover how our Sales Cloud solutions can transform your sales organization and drive sustainable business growth."
            },
            {
                "id": "8",
                "name": "data cloud",
                "imgSrc": "https://yugashift.com/images/salesforce.png",
                "content": "Welcome to Salesforce Data Cloud at Yuga Shift, where we unlock the potential of your data for intelligent decision-making and outstanding customer experiences. Seamlessly integrating data from various Salesforce clouds and external platforms, we provide a holistic view of your customers' journey. Our experts harmonize and enrich your data, leveraging AI and automation for actionable insights that drive business growth. Partner with Yuga Shift to harness the power of Salesforce Data Cloud, delivering personalized experiences and propelling your success with confidence."
            },
            {
                "id": "9",
                "name": "",
                "imgSrc": "https://yugashift.com/images/home_servicenow.png",
                "mobileimgSrc": "https://yugashift.com/images/mobile_view_home_hubspot.png",
                "content": "Welcome to our ServiceNow page! At YugaShift we understand the importance of efficient and streamlined IT service management. With ServiceNow, we help businesses transform their IT operations, automate workflows, and deliver exceptional service experiences. Our team of ServiceNow experts specializes in implementing and customizing ServiceNow's robust suite of IT service management, IT operations management, and IT business management solutions. From incident management and change management to asset management and service catalog, our tailored ServiceNow solutions empower you to drive operational efficiency, improve service quality, and enhance the overall IT experience."
            },
            {
                "id": "10",
                "name": "",
                "imgSrc": "https://yugashift.com/images/home_hubspot.png",
                "mobileimgSrc": "https://yugashift.com/images/mobile_view_home_servicenow.png",
                "content": "Welcome to our HubSpot page! In today's digital marketing landscape, attracting, engaging, and delighting customers is essential for business success. With HubSpot, we help you leverage the power of inbound marketing and sales automation to drive growth. Our team of HubSpot experts specializes in implementing and optimizing HubSpot's comprehensive suite of tools, including CRM, marketing automation, sales enablement, and customer service. Whether you need to generate leads, nurture prospects, or deliver exceptional customer experiences, our tailored HubSpot solutions empower you to achieve your marketing and sales goals."
            }
        ]
    },
    "contact": {
        "description": "Contact us today to learn more about how Yuga Shift can help you navigate the forces of transformation and thrive in the digital age.",
        "address": [
            "Yuga Shift Corporation",
            "<br />",
            "825 Watters Creek Blvd,",
            "<br />",
            "Building M, Suite 250, Allen, TX 75013",
            "<br />"
        ],
        "email": [
            "info@yugashift.com",
            "sales@yugashift.com"
        ],
        "phno": [{
            "id": "1",
            "number": 7329938667,
            "displayNum": "+1 (732) 993 8667"
        }]
    },
    "footer": {
        "location": "Texas",
        "address": [
            "<br />",
            "Yuga Shift Corporation",
            "<br />",
            "825 Watters Creek Blvd,",
            "<br />",
            "Building M, Suite 250, Allen,",
            "<br />",
            "TX 75013",
            "<br />"
        ],
        "email": [
            "info@yugashift.com",
            "sales@yugashift.com"
        ],
        "phno": [
            {
                "id": "1",
                "number": 7329938667,
                "displayNum": "+1 (732) 993 8667"
            }
        ]
    }
}

export const PLATFORM_LIST = [{
    id: '1',
    name: 'sales cloud',
    imgSrc: 'https://yugashift.com/images/salesforce.png',
}, {
    id: '2',
    name: 'marketing cloud',
    imgSrc: 'https://yugashift.com/images/salesforce.png',
}, {
    id: '3',
    name: 'experience cloud',
    imgSrc: 'https://yugashift.com/images/salesforce.png',
}, {
    id: '4',
    name: 'einstein analytics',
    imgSrc: 'https://yugashift.com/images/salesforce.png',
}, {
    id: '5',
    name: 'service cloud',
    imgSrc: 'https://yugashift.com/images/salesforce.png',
}, {
    id: '6',
    name: 'data cloud',
    imgSrc: 'https://yugashift.com/images/salesforce.png',
}, {
    id: '7',
    name: '',
    imgSrc: 'https://yugashift.com/images/servicenow.png',
}, {
    id: '8',
    name: '',
    imgSrc: 'https://yugashift.com/images/hubspot.png',
}]

export const MENU_ITEMS_LIST = {
    "about": {
        heading: 'About',
        name: 'about',
        description: 'Welcome to Yuga Shift - Empowering Business Transformation through Cycles of Innovation.',

        // subHeading: 'Lorem ipsum',
        subList: [{
            id: '1',
            name: 'Vision',
            target: '/about#vision'
        }, {
            id: '2',
            name: 'Mission',
            target: '/about#mission'
        }, {
            id: '3',
            name: 'Team',
            target: '/about#team'
        }]
    }, "solutions": {
        heading: 'Solutions', name: 'solutions',
        description: 'At Yuga Shift, we bring a comprehensive suite of services to help you unlock the full potential of SaaS platforms.',

        // subHeading: 'Lorem ipsum',
        subList: [{
            id: '1',
            name: 'Advisory',
            target: '/advisory'
        }, {
            id: '2',
            name: 'Implementations',
            target: '/implementation'
        }, {
            id: '3',
            name: 'Managed Services',
            target: '/manageservices'
        }, {
            id: '4',
            name: 'Custom Development',
            target: '/customdevelopment'
        }, {
            id: '5',
            name: 'Integration',
            target: '/integration'
        }, {
            id: '6',
            name: 'DevOps',
            target: '/devops'
        }, {
            id: '7',
            name: 'CRM Analytics',
            target: '/crmanalytics'
        }]
    }, "technologies": {
        heading: 'Technologies', name: 'technologies',
        description: 'At Yuga Shift, we are committed to leveraging our expertise in these technology platforms to help you navigate your digital journey.',

        // subHeading: 'Lorem ipsum',
        subList: [{
            id: '1',
            name: 'Salesforce Sales Cloud',
            target: '/technology#salesforcesalescloud'
        }, {
            id: '2',
            name: 'Salesforce Marketing Cloud',
            target: '/technology#salesforcemarketingcloud'
        }, {
            id: '3',
            name: 'Salesforce Experience Cloud',
            target: '/technology#salesforceexperiencecloud'
        }, {
            id: '4',
            name: 'Salesforce Service Cloud',
            target: '/technology#salesforceservicecloud'
        }, {
            id: '5',
            name: 'Salesforce Revenue Cloud',
            target: '/technology#salesforcerevenuecloud'
        }, {
            id: '6',
            name: 'Field Service Lightning',
            target: '/technology#fieldservicelightning'
        }, {
            id: '7',
            name: 'Einstein Analytics',
            target: '/technology#einsteinanalytics'
        }, {
            id: '8',
            name: 'Salesforce Data Cloud',
            target: '/technology#salesforcedatacloud'
        }, {
            id: '9',
            name: 'ServiceNow',
            target: '/technology#servicenow'
        }, {
            id: '10',
            name: 'HubSpot',
            target: '/technology#hubspot'
        }]

    }, "whyshift": {
        heading: 'Why Shift?', name: 'whyshift',
        description: 'At Yuga Shift, we go above and beyond to provide unparalleled value to our customers.',
        target: '/whyshift',

        // subHeading: 'Lorem ipsum',

        subList: [{
            id: '1',
            name: 'Speed to Value',
            target: '/whyshift#speed_to_value'
        }, {
            id: '2',
            name: 'Uncompromising Quality',
            target: '/whyshift#uncompromising_quality'
        }, {
            id: '3',
            name: 'Outcome-Oriented Engagement',
            target: '/whyshift#outcome_oriented'
        }, {
            id: '4',
            name: 'Competitive Pricing',
            target: '/whyshift#competitive_pricing'
        }
            // {
            //     id: '5',
            //     name: 'Uncompromised Value',
            //     target: '/whyshift'
            // }
        ]

    }, "contact": {
        heading: 'Contact', name: 'contact',
        target: '/contact',
        // description: 'Contact Us',

        // subHeading: 'Lorem ipsum',
        // subList: ['Vision', 'Mission', 'Team']
    }
}

export const CAROUSEL_CONTENT = [
    {
        id: 1,
        image: 'https://yugashift.com/images/banner_image_1.png',
        description: 'Supercharge Your Success with Salesforce Einstein AI'
    },
    {
        id: 2,
        image: 'https://yugashift.com/images/banner_image_2.png',
        description: 'Unlock Engaging Stakeholder Experiences Through Intuitive AI Solutions and Generative AI'
    },
    {
        id: 3,
        image: 'https://yugashift.com/images/banner_image_3.png',
        description: 'Achieve Certainty in Your Investments with Outcome-Based Services'
    },
    {
        id: 4,
        image: 'https://yugashift.com/images/banner_image_4.png',
        description: 'Welcome to an Integrated and Connected Future Powered by AI and Data'
    },
];

export const DASHBOARD_SIDEBAR_ITEMS = [{
    id: '1',
    name: 'Home',
    target: 'home'
}, {
    id: '2',
    name: 'About',
    target: 'about'
}, {
    id: '3',
    name: 'Advisory',
    target: 'advisory'
}, {
    id: '4',
    name: 'CRM Analytics',
    target: 'crmanalytics'
}, {
    id: '5',
    name: 'Custom Development',
    target: 'customdevelopment'
}, {
    id: '6',
    name: 'Devops',
    target: 'devops'
}, {
    id: '7',
    name: 'Implementation',
    target: 'implementation'
}, {
    id: '8',
    name: 'Integration',
    target: 'integration'
}, {
    id: '9',
    name: 'Managed Services',
    target: 'managedservices'
}, {
    id: '10',
    name: 'WhyShift',
    target: 'whyshift'
}, {
    id: '11',
    name: 'Technology',
    target: 'technology'
}, {
    id: '12',
    name: 'Contact',
    target: 'contact'
}, {
    id: '13',
    name: 'Footer',
    target: 'footer'
}];