import { useNavigate, Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { API_CALL, scrollWithOffset } from "../assets/common/functions";
import { API_BASE_URL, JSON_DATA } from "../assets/common/constants";
import { convertHtmlToReact } from "@hedgedoc/html-to-react";
import { useEffect, useState } from "react";

export const Footer = () => {

    let navigate = useNavigate();
    const [data, setData] = useState(JSON_DATA.footer);

    useEffect(() => {
        API_CALL(API_BASE_URL + '/footerdata', (res) => {
            setData(res.footer);
        }, (err) => { });
    }, []);

    return (<div className="footer-container">

        <div className="footer-column-holder">
            <div className="footer-column">
                <ul>
                    <li className="menu-heading">About</li>
                    <li><NavHashLink smooth to='/about#vision' scroll={el => scrollWithOffset(el)}>Vision</NavHashLink></li>
                    <li><NavHashLink smooth to='/about#mission' scroll={el => scrollWithOffset(el)}>Mission</NavHashLink></li>
                    <li><NavHashLink smooth to='/about#team' scroll={el => scrollWithOffset(el)}>Team</NavHashLink></li>
                    <li className="menu-heading">Why Shift?</li>
                    <li><NavHashLink smooth to='/whyshift#speed_to_value' scroll={el => scrollWithOffset(el)}>Speed to Value</NavHashLink></li>
                    <li><NavHashLink smooth to='/whyshift#uncompromising_quality' scroll={el => scrollWithOffset(el)}>Uncompromising Quality</NavHashLink></li>
                    <li><NavHashLink smooth to='/whyshift#outcome_oriented' scroll={el => scrollWithOffset(el)}>Outcome-Oriented</NavHashLink></li>
                    <li><NavHashLink smooth to='/whyshift#competitive_pricing' scroll={el => scrollWithOffset(el)}>Competitive Pricing</NavHashLink></li>
                </ul>
            </div>
            <div className="footer-column">
                <ul>
                    <li className="menu-heading">Solutions</li>
                    <li><Link to={'/advisory'}>Advisory</Link></li>
                    <li><Link to={'/implementation'}>Implementations</Link></li>
                    <li><Link to={'/manageservices'}>Managed Services</Link></li>
                    <li><Link to={'/customdevelopment'}>Custom Development</Link></li>
                    <li><Link to={'/integration'}>Integration</Link></li>
                    <li><Link to={'/devops'}>DevOps</Link></li>
                    <li><Link to={'/crmanalytics'}>CRM Analytics</Link></li>
                </ul>
            </div>
            <div className="footer-column">
                <ul>
                    <li className="menu-heading">Technologies</li>
                    <li><NavHashLink smooth to='/technology#salesforcemarketingcloud' scroll={el => scrollWithOffset(el)}>Salesforce Marketing Cloud</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#salesforceexperiencecloud' scroll={el => scrollWithOffset(el)}>Salesforce Experience Cloud</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#salesforceservicecloud' scroll={el => scrollWithOffset(el)}>Salesforce Service Cloud</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#salesforcerevenuecloud' scroll={el => scrollWithOffset(el)}>Revenue Cloud</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#fieldservicelightning' scroll={el => scrollWithOffset(el)}>Field Service Lightning</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#salesforcesalescloud' scroll={el => scrollWithOffset(el)}>Salesforce Sales Cloud</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#salesforcedatacloud' scroll={el => scrollWithOffset(el)}>Salesforce Data Cloud</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#servicenow' scroll={el => scrollWithOffset(el)}>ServiceNow</NavHashLink></li>
                    <li><NavHashLink smooth to='/technology#hubspot' scroll={el => scrollWithOffset(el)}>HubSpot</NavHashLink></li>
                </ul>
            </div>
            <div className="footer-column">
                <ul className="address-block">

                    <li>
                        <span className="contact-us-heading">Contact Us</span>
                        <br />
                        <span className="contact-us-content">Get in touch to set up discovery call</span>
                    </li>

                    {/* <li className="get-in-touch-block">
                        <input className="get-in-touch-input" type="email" placeholder="Your Email ID" />
                        <button className="get-in-touch-sendBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                <path d="M1.29539e-06 9L16.2 9L10.6 14.6L12 16L20 8L12 6.99382e-07L10.6 1.4L16.2 7L1.12054e-06 7L1.29539e-06 9Z" fill="var(--ternary-color)" />
                            </svg>
                        </button>
                    </li> */}

                    <li>
                        <span className="address-location">{data.location}</span>
                        {
                            convertHtmlToReact(data.address.join(''))
                        }
                        {
                            data.phno.map((row, key) => {
                                return <a key={key} href={`tel:${row.number}`}>{row.displayNum}<br /></a>
                            })
                        }

                    </li>
                    <div className="email-address-holder">
                        {
                            data.email.map((row, key) => {
                                return <li key={key} className="email-address">
                                    <svg viewBox="0 0 142 112" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                        <path d="M13 19.7499L57.95 53.462C65.6836 59.262 76.3164 59.262 84.05 53.462L129 19.7495" stroke="currentColor" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M121.75 5.25H20.25C12.2419 5.25 5.75 11.7419 5.75 19.75V92.25C5.75 100.258 12.2419 106.75 20.25 106.75H121.75C129.758 106.75 136.25 100.258 136.25 92.25V19.75C136.25 11.7419 129.758 5.25 121.75 5.25Z" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                                    </svg>
                                    <a key={key} href={`mailto:${row}`}>{row}<br /></a>
                                </li>
                            })
                        }
                    </div>
                </ul>
            </div>
        </div>

        <div className="footer-logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 139" fill="none">
                <path d="M343.223 0H305.205V111.197H343.223V0Z" fill="var(--ternary-color)" />
                <path d="M419.259 111.197H343.224V139H457.276V111.197V0H419.259V111.197Z" fill="var(--ternary-color)" />
                <path d="M548.549 56.7706V84.5736H586.567V111.197H510.802V27.8032H472.784V111.197V139H586.837V111.197H624.584V56.7706H586.567H548.549Z" fill="var(--ternary-color)" />
                <path d="M586.838 0H510.803V27.803H586.838V0Z" fill="var(--ternary-color)" />
                <path d="M754.148 56.1845H678.113V27.803H754.148V0H640.096V27.803V139H678.113V83.9875H754.148V139H792.166V27.803H754.148V56.1845Z" fill="var(--ternary-color)" />
                <path d="M1196.38 55.5991V139.001H1234.4V83.4021H1310.43V55.5991H1234.4H1196.38Z" fill="var(--ternary-color)" />
                <path d="M1310.43 0H1196.38V27.803H1310.43V0Z" fill="var(--ternary-color)" />
                <path d="M137.617 0H0V27.803H118.605L137.617 41.7007L118.605 55.5985H0V83.4015H137.617V55.5985H175.635V27.803H137.617V0Z" fill="var(--ternary-color)" />
                <path d="M213.652 55.5991H175.635V139.001H213.652V55.5991Z" fill="var(--ternary-color)" />
                <path d="M251.67 27.803H213.652V55.5985H289.687V27.803V0H251.67V27.803Z" fill="var(--ternary-color)" />
                <path d="M1089.32 54.7196H1013.28V82.515H1089.32V139H1127.34V0H1089.32V54.7196Z" fill="var(--ternary-color)" />
                <path d="M1013.28 82.5146H975.262V138.992H1013.28V82.5146Z" fill="var(--ternary-color)" />
                <path d="M1013.28 0H975.262V54.7196H1013.28V0Z" fill="var(--ternary-color)" />
                <path d="M1180.87 55.5991H1142.85V139.001H1180.87V55.5991Z" fill="var(--ternary-color)" />
                <path d="M1180.87 0H1142.85V27.803H1180.87V0Z" fill="var(--ternary-color)" />
                <path d="M1439.8 0H1325.95V55.5985H1363.96V27.7955H1439.8C1439.91 27.7955 1440 27.7053 1440 27.6002V0.19532C1440 0.0901475 1439.91 0 1439.8 0Z" fill="var(--ternary-color)" />
                <path d="M1363.96 83.4009H1325.95V138.999H1363.96V83.4009Z" fill="var(--ternary-color)" />
                <path d="M845.7 0V27.803H807.683V55.5985V83.4015H845.7H921.735V111.197H959.753V83.4015V55.5985H921.735H845.7V27.803H959.753V0H845.7Z" fill="var(--ternary-color)" />
                <path d="M921.735 111.197H807.683V139H921.735V111.197Z" fill="var(--ternary-color)" />
            </svg>
        </div>

        <div className="terms-and-conditions">
            <ul>
                <li>&#169; 2023 Yuga Shift. All rights reserved.</li>
            </ul>
            <ul>
                <li onClick={() => {
                    navigate('/privacypolicy');
                }}>Privacy & Cookies Policy</li>
            </ul>
        </div>

    </div>)
}