import { useEffect } from "react";
import { MasterHOC } from "../HOC/MasterHOC";
import { convertHtmlToReact } from "@hedgedoc/html-to-react";

export const Solutions = (props) => {
    const SolutionPage = () => {

        return <>
            <div className="banner-image" data-aos="fade-up">
                <img src={props.bannerImage} alt="" />
            </div>

            <div className="content-block" data-aos="fade-up" data-aos-delay="300">
                <p className="b-text-content">{convertHtmlToReact(props.bannerContent.join(''))}</p>
            </div>

            <ul className="piece-design-sequence-container">
                {
                    props.pieceDesignList.map((row, key) => {
                        return <div key={key} className="piece-design-item">
                            <div>{row}</div>
                        </div>
                    })
                }
            </ul>

            <div className="content-block" data-aos="fade-right">
                {
                    props.paragraphList.map((row, key) => {
                        return <p key={key} className="s-text-content">{row}</p>
                    })
                }
            </div>

            <div className="content-block right" data-aos="fade-left">
                <p className="b-text-content">Outcomes</p>
                <p className="s-text-content">{props.outcomesContent}</p>
            </div>
        </>
    }

    return MasterHOC(SolutionPage);
}