import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { API_BASE_URL } from "../assets/common/constants";
import { Sidebar } from "../components/Sidebar";
import { DashboardContent } from "../components/DashboardContent";
import { API_CALL, showNotification } from "../assets/common/functions";
import { AdminUserDetails } from "../components/AdminUserDetails";

export const Dashboard = () => {

    const [allData, setAllData] = useState('');

    const [selectedItem, setSelectedItem] = useState('home');
    const [selectedSubItem, setSelectedSubItem] = useState('aboutsection');
    const [userDetails, setUserdetails] = useState('');
    const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false);

    const onItemClickHandler = (id) => {
        setSelectedItem(id);
    }
    const toogleUserDetailsPopup = (bool) => {
        setShowUserDetailsPopup(bool);
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--nav-max-width', '100%');
        document.documentElement.style.setProperty('--container-padding', '0px');
        document.documentElement.style.setProperty('--ternary-color', '#fff');
        document.documentElement.style.setProperty('--secondary-color', '#000');
        document.documentElement.style.setProperty('--footer-text-color', '#878787');

        API_CALL(API_BASE_URL + '/getAllData', (res) => {
            setAllData(res);
        }, (err) => { });

        axios.get(API_BASE_URL + '/getuserdetails', { 'headers': { 'Authorization': `Bearer ${localStorage.getItem('token')}` } })
            .then((response) => {
                setUserdetails(response.data);
            });

    }, []);


    const setSelectedSubItemHandler = (val) => {
        setSelectedSubItem(val);
    }

    const onSaveDataHandler = async (heading, val) => {

        let obj = {
            nestedkeypair: heading,
            value: val,
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }

        await axios.post(API_BASE_URL + '/updatedata', obj, {
            headers: headers
        })
            .then((response) => {
                showNotification('Data saved succesfully');
            });
    }

    const onImageUploadHandler = async (idKey, idValue, imagekey, imagevalue) => {
        let obj = {
            idKey,
            idValue,
            imagekey,
            imagevalue,
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }

        await axios.post(API_BASE_URL + '/updateimage', obj, {
            headers: headers
        })
            .then((response) => {
                showNotification('Image Updated succesfully');
            }).catch((err) => { });
    }
    const onSingleImageUploadHandler = async (imagekey, imagevalue) => {
        let obj = {
            imagekey,
            imagevalue,
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }

        await axios.post(API_BASE_URL + '/updatesingleimage', obj, {
            headers: headers
        })
            .then((response) => {
                showNotification('Image Updated succesfully');
            });
    }

    const logoutBtnClickHandler = () => {
        localStorage.clear();
        window.location = "/admin";
    }

    return <div className="dashboard-container">

        <div className={`loader-holder ${allData ? 'hide' : ''}`}>
            <span className="loader"></span>
        </div>

        <nav className="navbar">
            <div className="logo_item">
                <img src={'https://yugashift.com/images/logo.svg'} alt="" />
            </div>

            <div className="navbar_content">
                {/* <i className='bx bx-sun' id="darkLight" title="Dark mode"></i> */}
                <i className='bx bx-log-out-circle' title="Logout"
                    onClick={logoutBtnClickHandler}></i>
                <span className="profile_name" title="Profile"
                    onClick={() => {
                        toogleUserDetailsPopup(true);
                    }}>{userDetails.name}</span>
            </div>
        </nav>

        <Sidebar selectedItem={selectedItem} setSelectedSubItem={setSelectedSubItemHandler}
            onItemClick={onItemClickHandler}
        />

        <DashboardContent
            data={allData?.[selectedItem]}
            selectedSubItem={selectedSubItem}
            selectedItem={selectedItem}

            saveData={onSaveDataHandler}
            imageUpload={onImageUploadHandler}
            singleimageUpload={onSingleImageUploadHandler}
        />

        {
            showUserDetailsPopup
                ? <AdminUserDetails
                    toogleUserDetailsPopup={toogleUserDetailsPopup}
                    userDetails={userDetails}
                />
                : ''
        }

    </div>
}