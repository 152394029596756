import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

export const Carousel = (props) => {

    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setCurrentIndex((window.innerWidth < 768) ? 1 : 0)
    }, []);

    useEffect(() => {
        const interval = setInterval(() => { carouselInfiniteScroll(); }, 3000);
        return () => { clearInterval(interval); }
    })

    const carouselInfiniteScroll = () => {
        if (currentIndex === ((props.bannerData.length) - 1)) {
            return setCurrentIndex((window.innerWidth < 768) ? 1 : 0);
        }
        return setCurrentIndex(currentIndex + 1);
    }

    return <section className="carousel-container">
        <div className={`carousel-image-holder`} style={{ transform: `translate( calc( ${((window.innerWidth < 768) ? currentIndex - 1 : currentIndex) * -100}% - calc( var(--container-padding) * 2 * ${(window.innerWidth < 768) ? currentIndex - 1 : currentIndex} ) ))` }}>

            <div className="carousel-item" >
                <picture>
                    <img src={props.bannerData?.[0].image} alt='' />
                </picture>
            </div>

            <div className="carousel-item" >
                <picture>
                    <source media="(max-width:768px)" srcSet={props.bannerData?.[1].mobile_image} />
                    <img src={props.bannerData?.[1].image} alt='' />
                </picture>
                <span className="carousel-description">
                    <button className="btn btn2" onClick={() => { navigate(props.bannerData?.[1].action_target); }}>
                        {props.bannerData?.[1].action_name}
                    </button>
                </span>
            </div>

            <div className="carousel-item" >
                <picture>
                    <source media="(max-width:768px)" srcSet={props.bannerData?.[2].mobile_image} />
                    <img src={props.bannerData?.[2].image} alt="" />
                </picture>
                <span className="carousel-description">
                    <button className="btn btn3" onClick={() => { navigate(props.bannerData?.[2].action_target); }}>
                        {props.bannerData?.[2].action_name}
                    </button>
                </span>
            </div>

            <div className="carousel-item" >
                <picture>
                    <source media="(max-width:768px)" srcSet={props.bannerData?.[3].mobile_image} />
                    <img src={props.bannerData?.[3].image} alt="" />
                </picture>
                <span className="carousel-description">
                    <button className="btn btn2" onClick={() => { navigate(props.bannerData?.[3].action_target); }}>
                        {props.bannerData?.[3].action_name}
                    </button>
                </span>
            </div>
        </div>

        <div className="dots-holder">
            <span className={`dot ${(currentIndex === 0) ? 'active' : ''}`} onClick={() => { setCurrentIndex(0) }}></span>
            <span className={`dot ${(currentIndex === 1) ? 'active' : ''}`} onClick={() => { setCurrentIndex(1) }}></span>
            <span className={`dot ${(currentIndex === 2) ? 'active' : ''}`} onClick={() => { setCurrentIndex(2) }}></span>
            <span className={`dot ${(currentIndex === 3) ? 'active' : ''}`} onClick={() => { setCurrentIndex(3) }}></span>
        </div>
    </section >
}