import { useEffect } from "react";
import { MasterHOC } from "../HOC/MasterHOC";

export const Contact = () => {

    const ContactPage = () => {
        useEffect(() => {
            document.documentElement.style.setProperty('--secondary-color', '#000');
            document.documentElement.style.setProperty('--ternary-color', '#fff');
            document.documentElement.style.setProperty('--footer-text-color', '#CDCDCD');
        }, []);
        return <section className={`home-section`}>
            <div className="content-block">
                <p className="b-text-content">Contact Us</p>
            </div>
        </section>
    }
    return MasterHOC(ContactPage);
}