import { useState } from 'react';
import { IMAGE_URL, MENU_ITEMS_LIST } from '../assets/common/constants';
import { SubMenuBar } from './SubMenuBar';
import { MobileSubMenuBar } from "./MobileSubMenuBar"
import { useNavigate } from 'react-router-dom';

export const MenuBar = (props) => {

    const [isMobileViewSubMenuOpen, setisMobileViewSubMenuOpen] = useState(false);
    const [activeSubMenu, setActionSubmenu] = useState('about');
    const [IsSubMenuOpen, setSubMenuOpen] = useState(false);

    const navigate = useNavigate();
    const onMenuItemClickHandler = (row) => {
        if (row.name === "contact") {
            props.onContactPopupClick(true);
            return;
        }
        setActionSubmenu(row.name)
        setSubMenuOpen(true);
    }

    const onCloseMenuItem = () => {
        setSubMenuOpen(false);
        let wrapperContainer = document.body.querySelector('.wrapper-container');
        wrapperContainer.classList.remove('blur');
    }

    const closeMobileSubMenu = () => {
        setisMobileViewSubMenuOpen(false);
    }

    return <>
        <nav className="navbar-container" tabIndex={0}
        // onBlur={onCloseMenuItem}
        >
            <div className="logo">
                <img src={IMAGE_URL + 'logo.svg'} alt='' onClick={() => {
                    navigate("/");
                }} />
            </div>

            <ul className='nav-items-holder'>
                {
                    Object.entries(MENU_ITEMS_LIST).map(([key, value], index) => {
                        return <li key={index} className={`nav-item`}
                            onClick={() => onMenuItemClickHandler(value)}
                            onMouseOver={() => {
                                let wrapperContainer = document.body.querySelector('.wrapper-container');
                                wrapperContainer.classList.add('blur');


                                if (value.name === "contact") {
                                    onCloseMenuItem();
                                    return;
                                }
                                setActionSubmenu(value.name)
                                setSubMenuOpen(true);
                            }}
                        >{value.heading}</li>
                    })
                }
            </ul>

            <div className={`hamburger-holder ${isMobileViewSubMenuOpen ? 'active' : ''}`}
                onClick={(() => {
                    setisMobileViewSubMenuOpen(prev => !prev)
                })}>
                <span className='hamburger-line-1'></span>
                <span className='hamburger-line-2'></span>
            </div>

            <MobileSubMenuBar status={isMobileViewSubMenuOpen} onCloseMobileSubMenu={closeMobileSubMenu} onContactPopupClick={props.onContactPopupClick} />
            <SubMenuBar activeSubMenu={activeSubMenu} status={IsSubMenuOpen} onCloseSubMenu={onCloseMenuItem} />

        </nav>

        <div className='nav-overlay'></div>

    </>
}