import { useState, useEffect } from "react";
import { Solutions } from "../components/Solutions";
import { API_BASE_URL, JSON_DATA } from "../assets/common/constants";
import { API_CALL } from "../assets/common/functions";

export const Advisory = () => {

    // const [data, setData] = useState(JSON_DATA.advisory);
    const [data, setData] = useState();

    useEffect(() => {
        document.documentElement.style.setProperty('--secondary-color', '#000');
        document.documentElement.style.setProperty('--ternary-color', '#fff');
        document.documentElement.style.setProperty('--footer-text-color', '#CDCDCD');

        API_CALL(API_BASE_URL + '/advisorydata', (res) => {
            setData(res.advisory);
        }, (err) => { });
    }, []);

    return <>

        <div className={`loader-holder ${data ? 'hide' : ''}`}>
            <span className="loader"></span>
        </div>
        {
            data
                ? <Solutions
                    bannerImage={data.bannerImage}
                    bannerContent={data.bannerContent}
                    pieceDesignList={data.pieceDesignList}
                    paragraphList={data.paragraphList}
                    outcomesContent={data.outcomesContent}
                />
                : ''
        }

    </>
}