import { useEffect, useState } from "react";
import { DASHBOARD_SIDEBAR_ITEMS, JSON_DATA } from "../assets/common/constants";
import { convertCamelCaseToNormalCase } from "../assets/common/functions";

export const Sidebar = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        props.setSelectedSubItem(Object.keys(JSON_DATA[props.selectedItem])[activeIndex]);
    }, [props.selectedItem, activeIndex]);

    useEffect(() => {
        setActiveIndex(0);
    }, [props.selectedItem]);


    return <nav className="sidebar">
        <div className="menu_content">
            <ul className="menu_items">
                {
                    DASHBOARD_SIDEBAR_ITEMS.map((row, key) => {
                        return <li key={key} className="item">
                            <div href="#" className={`nav_link submenu_item ${(props.selectedItem === row.target) ? 'show_submenu' : ''}`}
                                onClick={() => props.onItemClick(row.target)}>
                                <span className="navlink_icon">
                                    <i className="bx bx-home-alt"></i>
                                </span>
                                <span className="navlink">{row.name}</span>
                                <i className="bx bx-chevron-right arrow-left"></i>
                            </div>

                            <ul className="menu_items sub-menu">
                                {
                                    Object.keys(JSON_DATA[row.target])?.map((rw, ky) => {
                                        return <span key={ky}
                                            className={`nav_link sublink ${(ky === activeIndex) ? 'active' : ''}`}
                                            onClick={() => {
                                                setActiveIndex(ky);
                                            }}
                                        >
                                            <i className='bx bxs-right-arrow-alt'></i>
                                            {convertCamelCaseToNormalCase(rw)}
                                        </span>
                                    })
                                }
                            </ul>
                        </li>
                    })
                }
            </ul>

            {/* <div className="bottom_content">
                <div className="bottom expand_sidebar">
                    <span> Expand</span>
                    <i className='bx bx-log-in' ></i>
                </div>
                <div className="bottom collapse_sidebar">
                    <span> Collapse</span>
                    <i className='bx bx-log-out'></i>
                </div>
            </div> */}

        </div>
    </nav>
}